import { DateTimePicker } from "@/components/DateTimePicker";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Separator } from "@/components/ui/separator";
import { supabase } from "@/lib/supabase";
import { useEditorStore } from "@/store/editor.store";
import { ClockIcon, InfoCircledIcon, LockClosedIcon } from "@radix-ui/react-icons";
import { ChangeEvent, useEffect, useState } from "react";
import { toast } from "sonner";
import { QuizConfig } from "@/types/quiz";
import { addDays, formatDistance } from "date-fns";
import { Group } from "@/types/participant";
import { listGroups } from "@/lib/data/group.service";
import { Checkbox } from "@/components/ui/checkbox";
import { cn } from "@/lib/utils";

export default function CompanyQuizAccessSettings() {
  const { quiz, setQuiz } = useEditorStore();
  const [groups, setGroups] = useState<Group[]>([]);
  const [isLoadingGroups, setIsLoadingGroups] = useState(false);
  
  useEffect(() => {
    if (!quiz) return;
    
    const fetchGroups = async () => {
      setIsLoadingGroups(true);
      try {
        const groupsData = await listGroups(quiz.workspace_id);
        setGroups(groupsData);
      } catch (error) {
        console.error("Error fetching groups:", error);
        toast.error("Failed to load groups");
      } finally {
        setIsLoadingGroups(false);
      }
    };
    
    fetchGroups();
  }, [quiz?.workspace_id]);
  
  if (!quiz) {
    return <div>Loading...</div>;
  }

  const onChange = async (config: QuizConfig) => {
    setQuiz({ ...quiz, config: { ...quiz.config, ...config } });
    // save to supabase db
    await supabase
      .from("quizs")
      .update({ config: { ...config } })
      .eq("id", quiz.id);
  };


  if (!quiz.config.timeWindow) {
    quiz.config.timeWindow = {
      start: addDays(new Date(), 1).getTime(),
      duration: 10,
    };
  }

  const handleWindowStartDateChange = async (ms: number) => {
    if (!ms) {
      return;
    }

    const timeWindow = {
      start: ms,
      duration: quiz.config.timeWindow?.duration || 0,
    }
    setQuiz({
      ...quiz,
      config: {
        ...quiz.config,
        timeWindow,
      },
    });

    toast.success("Quiz schedule updated");

    // save to supabase db
    await supabase
      .from("quizs")
      .update({ config: { ...quiz.config, timeWindow } })
      .eq("id", quiz.id);

  };

  const handleWindowDurationChange = async (
    e: ChangeEvent<HTMLInputElement>,
  ) => {
    const seconds = e.target.value ? parseInt(e.target.value) : 0;

    setQuiz({
      ...quiz,
      config: {
        ...quiz.config,
        timeWindow: {
          ...quiz.config.timeWindow!,
          duration: seconds,
        },
      },
    });

    await supabase
      .from("quizs")
      .update({
        config: {
          ...quiz.config,
          timeWindow: {
            ...quiz.config.timeWindow!,
            duration: seconds,
          },
        },
      })
      .eq("id", quiz.id);

    toast.success("Quiz schedule updated");
  };

  const handleTimeLimitChange = (e: ChangeEvent<HTMLInputElement>) => {
    const minutes = Number(e.target.value);
    if (isNaN(minutes)) {
      toast.error("Please enter a valid number.");
      return;
    }
    const seconds = minutes * 60;
    onChange({
      ...quiz.config,
      timer: { ...quiz.config.timer, seconds: seconds, minutes },
    });
    toast.success("Time limit updated")
  };
  
  const handleGroupAccessChange = async (groupId: string, checked: boolean) => {
    // Initialize allowedGroups if it doesn't exist
    const currentAllowedGroups = quiz.config.allowedGroups || [];
    
    let newAllowedGroups: string[] | null;
    
    if (groupId === 'all') {
      // If "All" is selected, clear the allowed groups list
      newAllowedGroups = []
    } else {
      // Otherwise, add or remove the group from the list
      if (checked) {
        newAllowedGroups = [...currentAllowedGroups, groupId];
      } else {
        newAllowedGroups = currentAllowedGroups.filter(id => id !== groupId);
      }
    }
    
    // Update the quiz config
    const updatedConfig = {
      ...quiz.config,
      allowedGroups: newAllowedGroups
    };
    
    setQuiz({
      ...quiz,
      config: updatedConfig
    });
    
    // Save to supabase
    await supabase
      .from("quizs")
      .update({ config: updatedConfig })
      .eq("id", quiz.id);
    
    toast.success("Group access updated");
  };

  return (
    <section className="mt-6 flex flex-col gap-4">
      <section>
        <h4 className="flex items-center gap-2 leading-7 font-semibold">
          <ClockIcon /> Quiz Availability
        </h4>
        <p className="text-muted-foreground px-6 text-sm">
          For how long should students be able to enter the quiz.
        </p>

        <div className="mt-4 flex flex-col px-4">
          <div className="border bg-white p-4 rounded-lg">
            <p className="text-muted-foreground">Students can only start the quiz during the scheduled time window.</p>
            <section className="">
              <div className="flex items-center gap-1.5 mt-4">
                <InfoCircledIcon /> <span className="text-muted-foreground text-xs">Use the start date and duration to define the period when students can enter/start the quiz.</span>
              </div>

              <div className="mt-3 grid grid-cols-1 lg:grid-cols-4 gap-3">
                <div className="lg:col-span-2">
                  <Label className="text-muted-foreground">Start</Label>
                  <DateTimePicker
                    defaultValue={quiz.config.timeWindow?.start}
                    onChange={handleWindowStartDateChange}
                  />
                </div>
                <div>
                  <Label className="text-muted-foreground">Entry period</Label>
                  <div className="rounded-lg border flex w-full">
                    <Input
                      // type="number"
                      name="durationMinutes"
                      className="bg-zinc-100 rounded-r-none border-none focus:outline-none focus-visible:ring-0"
                      placeholder="Duration in minutes"
                      onChange={handleWindowDurationChange}
                      defaultValue={quiz.config.timeWindow?.duration ?? 0}
                    />
                    <div className="bg-white px-4 text-zinc-500 rounded-r-lg items-center text-center text-sm border-l flex">
                      Mins
                    </div>
                  </div>
                </div>
                <div className="">
                  <Label className="text-muted-foreground">Session Time</Label>
                  <div className="rounded-lg border flex w-full">
                    <Input
                      // type="number"
                      name="durationMinutes"
                      className="bg-zinc-100 rounded-r-none border-none focus:outline-none focus-visible:ring-0"
                      placeholder="Time in minutes"
                      onChange={handleTimeLimitChange}
                      defaultValue={quiz.config.timer.minutes ?? 30}
                      prefix="minutes"
                    />
                    <div className="bg-white text-zinc-500 rounded-r-lg items-center text-center text-sm px-4 border-l flex">
                      Mins
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-4 text-sm">
                Students will be permitted to start the quiz <span className="font-bold">{formatDistance(quiz.config.timeWindow?.start, new Date(), { addSuffix: true })}</span>,
                and the entry window will last for <span className="font-bold">{quiz.config.timeWindow?.duration} minutes</span>.
              </div>


            </section>
          </div>
        </div>
      </section>

      <Separator />


      <section>
        <h4 className="flex items-center gap-2 leading-7 font-semibold">
          <LockClosedIcon /> Group Access
        </h4>
        <p className="text-muted-foreground px-6 text-sm">
          Control which groups can access this quiz.
        </p>
        
        <div className="mt-4 flex flex-col px-4">
          <div className="border bg-white p-4 rounded-lg">
            <div className="flex items-center space-x-2">
              <Checkbox 
                id="group-all" 
                checked={!quiz.config.allowedGroups?.length}
                onCheckedChange={(checked) => handleGroupAccessChange('all', !!checked)}
              />
              <label
                htmlFor="group-all"
                className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                All Groups (Default)
              </label>
            </div>
            
            <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3">
              {isLoadingGroups ? (
                <div className="text-sm text-muted-foreground">Loading groups...</div>
              ) : groups.length > 0 ? (
                groups.map((group) => (
                  <div key={group.id} className="flex items-center space-x-2">
                    <Checkbox 
                      id={`group-${group.id}`} 
                      checked={quiz.config.allowedGroups?.includes(group.id)}
                      onCheckedChange={(checked) => handleGroupAccessChange(group.id, !!checked)}
                    />
                    <label
                      htmlFor={`group-${group.id}`}
                      className={cn(
                        "text-sm font-medium leading-none peer-disabled:cursor-not-allowed",
                      )}
                    >
                      {group.name}
                    </label>
                  </div>
                ))
              ) : (
                <div className="text-sm text-muted-foreground">No groups found for this workspace.</div>
              )}
            </div>
            
            <div className="mt-4 text-sm text-muted-foreground">
              {!quiz.config.allowedGroups || quiz.config.allowedGroups.length === 0 ? (
                <span>All groups can access this quiz.</span>
              ) : (
                <span>Only selected groups can access this quiz.</span>
              )}
            </div>
          </div>
        </div>
      </section>

    </section >
  );
}
