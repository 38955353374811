import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { ensureUserProfile } from "@/lib/data/profile.service";
import { supabase } from "@/lib/supabase";
import { useAuthStore } from "@/store/auth.store";
import { useWorkspaceStore } from "@/store/workspace.store";
import { FormEvent, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "sonner";

export default function StudioStartPage() {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const workspaces = useWorkspaceStore();
  const auth = useAuthStore();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (!auth.user) {
      return;
    }

    if (!name) {
      toast("Please fill in all fields");
      return;
    }

    setLoading(true);
    await ensureUserProfile({ user: auth.user, userId: auth.user.id });
    const { data, error } = await supabase
      .from("workspaces")
      .insert({ name })
      .select("*")
      .single();
    if (error) {
      toast(error.message);
      return;
    }

    // create role and assign to the user
    const { error: roleError } = await supabase
      .from("workspace_users")
      .insert([
        {
          workspaceId: data.id,
          userId: auth.user?.id,
          role: "owner",
        },
      ])
      .select("workspace_id")
      .single();
    if (roleError) {
      toast(roleError.message);
      return;
    }

    workspaces.setWorkspaces([data]);
    workspaces.setCurrentWorkspace(0);
    setLoading(false);
    // redirect to the workspace
    navigate("/studio");
  };

  return (
    <div className="container mx-auto py-24">
      <Card className="mx-auto lg:max-w-lg">
        <CardHeader>
          <CardTitle>Welcome to your studio!</CardTitle>
          <CardDescription>
            Get started by creating a workspace for your quizzes.
          </CardDescription>
        </CardHeader>
        <CardContent>
          <form onSubmit={handleSubmit}>
            <div className="form-control">
              <div>
                <Label>Name</Label>
                <Input
                  type="text"
                  name="name"
                  onChange={(e) => setName(e.currentTarget.value)}
                  placeholder="e.g: Company's name"
                />
              </div>
              <div className="mt-6 flex justify-end">
                <Button
                  disabled={loading}
                  type="submit"
                  className="cursor-pointer"
                >
                  {loading ? "Creating ..." : "Create Workspace"}
                </Button>
              </div>
            </div>
          </form>
        </CardContent>
      </Card>
    </div>
  );
}
