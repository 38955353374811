import { useEffect, useState } from "react";
import { listQuizzesWithQuestions } from "@/lib/data/studio/quiz.service";
import { Quiz } from "@/types/quiz";
import { Button } from "@/components/ui/button";
import {
  LayersIcon,
  DotsHorizontalIcon,
  MagnifyingGlassIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  Pencil1Icon,
  ClipboardIcon,
  Link1Icon,
  ExternalLinkIcon,
} from "@radix-ui/react-icons";
import { Skeleton } from "@/components/ui/skeleton";
import { useCurrentWorkspaceId } from "@/hooks/use-current-ws";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuSeparator, DropdownMenuTrigger } from "@/components/ui/dropdown-menu";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { Badge } from "@/components/ui/badge";
import { Input } from "@/components/ui/input";
import { toast } from "sonner";
import QuizModal from "@/studio/QuizModal";
import PageShell from "@/components/PageShell";
import { formatTime, formatDate, formatSchedule } from "@/lib/utils";
import { Link } from "react-router";
import { PlusIcon } from "lucide-react";

type SortField = 'title' | 'questions_count' | 'responses' | 'created_at' | 'scheduled';
type SortDirection = 'asc' | 'desc';

export default function QuizzesIndex() {
  const [isLoading, setIsLoading] = useState(true);
  const [quizzes, setQuizzes] = useState<Quiz[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortField, setSortField] = useState<SortField>('created_at');
  const [sortDirection, setSortDirection] = useState<SortDirection>('desc');
  const workspaceId = useCurrentWorkspaceId();

  useEffect(() => {
    if (!workspaceId) return;
    listQuizzesWithQuestions(workspaceId)
      .then(setQuizzes)
      .finally(() => setIsLoading(false));
  }, [workspaceId]);

  // Function to handle sorting
  const handleSort = (field: SortField) => {
    if (sortField === field) {
      // Toggle direction if clicking the same field
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      // Set new field and default to ascending
      setSortField(field);
      setSortDirection('asc');
    }
  };

  // Filter quizzes based on search query
  const filteredQuizzes = quizzes.filter(quiz =>
    quiz.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
    (quiz.description && quiz.description.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  // Sort quizzes based on sort field and direction
  const sortedQuizzes = [...filteredQuizzes].sort((a, b) => {
    let comparison = 0;

    switch (sortField) {
      case 'title':
        comparison = a.title.localeCompare(b.title);
        break;
      case 'questions_count':
        comparison = a.questions_count[0].count - b.questions_count[0].count;
        break;
      case 'responses':
        comparison = a.responses[0]?.count - b.responses[0]?.count;
        break;
      case 'created_at':
        comparison = new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
        break;

      case 'scheduled':
        const aTime = a.config.timeWindow?.start || 0;
        const bTime = b.config.timeWindow?.start || 0;
        comparison = aTime - bTime;
        break;
      default:
        break;
    }

    return sortDirection === 'asc' ? comparison : -comparison;
  });

  // Function to copy quiz link to clipboard
  const copyQuizLink = (quizId: string) => {
    navigator.clipboard.writeText(`${window.location.origin}/quiz/${quizId}`);
    toast.success("Quiz link copied to clipboard");
  };

  if (isLoading) {
    return (
      <PageShell items={[
        { label: "Quizzes", icon: <LayersIcon className="h-4 w-4" /> }
      ]} actions={<div />}>
        <div className="px-4 py-2 lg:px-8">
          <div className="flex justify-between items-center mb-4">
            <Skeleton className="h-10 w-[250px]" />
            <Skeleton className="h-10 w-[120px]" />
          </div>
          <Skeleton className="h-12 w-full mb-4" />
          <div className="rounded-md border">
            <Skeleton className="h-10 w-full" />
            {[...Array(5)].map((_, i) => (
              <Skeleton className="h-16 w-full" key={i} />
            ))}
          </div>
        </div>
      </PageShell>
    );
  }

  return (
    <>
      <PageShell
        items={[
          { label: "Quizzes", icon: <LayersIcon className="h-4 w-4" /> }
        ]}
        actions={<></>}
      >
        <div className="px-3">
          {/* Search and actions bar */}
          <div className="flex flex-row justify-between items-start sm:items-center gap-4 pb-4">
            <div className="relative sm:w-auto max-w-md">
              <MagnifyingGlassIcon className="absolute left-3 top-2.5 h-4 w-4 text-muted-foreground" />
              <Input
                placeholder="Search quizzes..."
                className="pl-9 w-full bg-white border-gray-200 max-w-[250px]"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            <QuizModal mode="create">
              <Button size={"sm"} className="text-xs items-center">
                <PlusIcon className="h-3 w-3" />
                Create Quiz
              </Button>
            </QuizModal>
          </div>

          {/* Empty state */}
          {!quizzes.length && (
            <div className="rounded-lg border border-dashed p-12 text-center">
              <div className="mx-auto flex h-20 w-20 items-center justify-center rounded-full bg-primary/10">
                <LayersIcon className="h-10 w-10 text-primary" />
              </div>
              <h2 className="mt-6 mb-2 text-2xl font-semibold">No quizzes yet</h2>
              <p className="mb-6 text-muted-foreground max-w-md mx-auto">
                Create your first quiz to get started. Quizzes are a great way to engage
                your audience and collect valuable feedback.
              </p>
              <QuizModal />
            </div>
          )}

          {/* Quizzes table */}
          {quizzes.length > 0 && (
            <div className="rounded-md border">
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead
                      className="w-[300px] cursor-pointer"
                      onClick={() => handleSort('title')}
                    >
                      <div className="flex items-center">
                        Quiz
                        {sortField === 'title' && (
                          sortDirection === 'asc' ?
                            <ChevronUpIcon className="ml-1 h-4 w-4" /> :
                            <ChevronDownIcon className="ml-1 h-4 w-4" />
                        )}
                      </div>
                    </TableHead>
                    <TableHead
                      className="cursor-pointer text-center"
                      onClick={() => handleSort('questions_count')}
                    >
                      <div className="flex items-center justify-center">
                        Questions
                        {sortField === 'questions_count' && (
                          sortDirection === 'asc' ?
                            <ChevronUpIcon className="ml-1 h-4 w-4" /> :
                            <ChevronDownIcon className="ml-1 h-4 w-4" />
                        )}
                      </div>
                    </TableHead>
                    <TableHead
                      className="cursor-pointer text-center"
                      onClick={() => handleSort('responses')}
                    >
                      <div className="flex items-center justify-center">
                        Responses
                        {sortField === 'responses' && (
                          sortDirection === 'asc' ?
                            <ChevronUpIcon className="ml-1 h-4 w-4" /> :
                            <ChevronDownIcon className="ml-1 h-4 w-4" />
                        )}
                      </div>
                    </TableHead>
                    <TableHead
                      className="cursor-pointer text-center hidden md:table-cell"
                      onClick={() => handleSort('created_at')}
                    >
                      <div className="flex items-center justify-center">
                        Created
                        {sortField === 'created_at' && (
                          sortDirection === 'asc' ?
                            <ChevronUpIcon className="ml-1 h-4 w-4" /> :
                            <ChevronDownIcon className="ml-1 h-4 w-4" />
                        )}
                      </div>
                    </TableHead>
                    <TableHead
                      className="cursor-pointer text-center hidden md:table-cell"
                      onClick={() => handleSort('scheduled')}
                    >
                      <div className="flex items-center justify-center">
                        Scheduled
                        {sortField === 'scheduled' && (
                          sortDirection === 'asc' ?
                            <ChevronUpIcon className="ml-1 h-4 w-4" /> :
                            <ChevronDownIcon className="ml-1 h-4 w-4" />
                        )}
                      </div>
                    </TableHead>

                    <TableHead className="w-[80px]"></TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {sortedQuizzes.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={6} className="text-center py-6 text-muted-foreground">
                        No quizzes match your search
                      </TableCell>
                    </TableRow>
                  ) : (
                    sortedQuizzes.map((quiz) => (
                      <TableRow key={quiz.id} className="bg-white hover:bg-zinc-50">
                        <TableCell>
                          <div className="flex flex-col">
                            <Link
                              to={`/studio/quiz/${quiz.id}/`}
                              className="font-medium hover:underline"
                            >
                              {quiz.title}
                            </Link>
                            <p className="text-xs text-muted-foreground line-clamp-1">
                              {quiz.description || "No description"}
                            </p>
                            <div className="flex flex-wrap gap-2 mt-1">
                              {quiz.config.timer.mode !== "off" && (
                                <Badge variant="outline" className="text-xs">
                                  {quiz.config.timer.mode === "perQuestion"
                                    ? `${quiz.config.timer.seconds}s per question`
                                    : `${formatTime(quiz.config.timer.seconds)} total`}
                                </Badge>
                              )}
                            </div>
                          </div>
                        </TableCell>
                        <TableCell className="text-center">
                          <Badge variant="secondary">{quiz.questions_count[0].count}</Badge>
                        </TableCell>
                        <TableCell className="text-center">
                          <Badge variant="secondary">{quiz.responses[0]?.count || 0}</Badge>
                        </TableCell>
                        <TableCell className="text-center hidden md:table-cell text-muted-foreground text-sm">
                          {formatDate(quiz.created_at)}
                        </TableCell>
                        <TableCell className="text-center hidden md:table-cell text-muted-foreground text-sm">
                          {formatSchedule(quiz.config.timeWindow)}
                        </TableCell>

                        <TableCell>
                          <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                              <Button variant="ghost" size="icon" className="h-8 w-8">
                                <DotsHorizontalIcon className="h-4 w-4" />
                                <span className="sr-only">Open menu</span>
                              </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent align="end">
                              <DropdownMenuItem asChild>
                                <QuizModal mode="edit" quiz={quiz}>
                                  <Button variant="link" className="flex gap-4 px-2 text-sm"><Pencil1Icon className="h-4 w-4" /> Edit Quiz</Button>
                                </QuizModal>
                              </DropdownMenuItem>
                              <DropdownMenuItem asChild>
                                <Link to={`/studio/quiz/${quiz.id}/questions`} className="cursor-pointer">
                                  <Pencil1Icon className="mr-2 h-4 w-4" /> Edit Questions
                                </Link>
                              </DropdownMenuItem>
                              <DropdownMenuItem asChild>
                                <Link to={`/studio/quiz/${quiz.id}/responses`} className="cursor-pointer">
                                  <ClipboardIcon className="mr-2 h-4 w-4" /> View Responses
                                </Link>
                              </DropdownMenuItem>
                              <DropdownMenuSeparator />
                              <DropdownMenuItem onClick={() => copyQuizLink(quiz.id)}>
                                <Link1Icon className="mr-2 h-4 w-4" /> Copy Link
                              </DropdownMenuItem>
                              <DropdownMenuItem asChild>
                                <a href={`/quiz/${quiz.id}`} target="_blank" rel="noopener noreferrer" className="cursor-pointer">
                                  <ExternalLinkIcon className="mr-2 h-4 w-4" /> Open Quiz
                                </a>
                              </DropdownMenuItem>
                              {/* <DropdownMenuItem className="text-destructive focus:text-destructive">
                                <TrashIcon className="mr-2 h-4 w-4" /> Delete
                              </DropdownMenuItem> */}
                            </DropdownMenuContent>
                          </DropdownMenu>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </div>
          )}
        </div>
      </PageShell >
    </>
  );
}


