import { GroupWithMetadata } from "@/types/participant";
import { ColumnDef } from "@tanstack/react-table";

export const GroupColumns: ColumnDef<GroupWithMetadata>[] = [
  {
    id: 'id',
    accessorKey: "id",
    header: "",
    cell: () => null
  },
  {
    id: 'name',
    accessorKey: "name",
    header: "Name"
  },
  {
    id: 'students',
    accessorFn: (row) => {
      return row.participants[0].count
    }
  }
];
