import { useAuthStore } from "@/store/auth.store";
import { useEffect, useState } from "react";
import { Navigate, redirect } from "react-router";
import * as Sentry from '@sentry/react'
import { supabase } from "@/lib/supabase";
import { QuizSession } from "@/types/session";
import { listPreviousSessions } from "@/lib/data/session.service";
import { useBootstrapStore } from "@/store/bootstrap.store";
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import UserAvatar from "@/components/UserAvatar";
import { Separator } from "@/components/ui/separator";
import SessionRecord from "../../quiz/SessionRecord";

type Profile = {
    id: string;
    name: string;
    email: string;
    avatar_url: string;
}

export const clientLoader = () => {
    console.log('client actions')
    return {}
}

export default function ProfilePage() {
    const { isLoading } = useBootstrapStore();
    const [loading, setLoading] = useState(true);
    const user = useAuthStore((auth) => auth.user)
    const [profile, setProfile] = useState<Profile | null>(null);
    const [sessions, setSessions] = useState<QuizSession[]>([]);

    useEffect(() => {
        if (isLoading) {
            return
        }
        if (!user) {
            // if no user found, redirect to login
            setLoading(false)
            return
        }
        const fetchProfile = async () => {
            setLoading(true)
            const { data: profile, error: profileError } = await supabase
                .from("profiles")
                .select()
                .eq("id", user.id)
                .single()

            if (profileError) {
                Sentry.captureMessage("Error fetching profile", {
                    extra: {
                        userId: user.id
                    }
                })
                redirect("/404")
            }
            setProfile(profile)
            setLoading(false)

            // load previous sessions
            const prevSess = await listPreviousSessions(user.id)
            setSessions(prevSess)
        }

        fetchProfile()
    }, [user?.id, isLoading])

    if (isLoading || loading) {
        return <div>Loading...</div>;
    }

    if (!isLoading && !user) {
        return <Navigate to={`/login?next=${location.pathname}`} />;
    }

    if (!profile) {
        return <Navigate to={`/404`} />;
    }

    return (
        <div className="h-screen flex flex-col">
            <div className="bg-white border-b">
                <Header />
            </div>

            <div className="container mt-3">
                <div className="flex flex-col">
                    <div className="flex gap-6 items-center">
                        <UserAvatar name={profile.name} url={profile?.avatar_url} size={"md"} />
                        <div className="">
                            <h4 className="text-2xl font-bold">{profile?.name}</h4>
                            <p>{profile?.email}</p>
                        </div>
                    </div>

                    <Separator className="mt-3" />

                    <div className="flex flex-col w-full md:pl-16">

                        <h1 className="text-2xl font-bold mt-3 text-muted-foreground">Quizzes taken</h1>

                        {sessions.length > 0 && (
                            <div className="grid grid-cols-1 lg:grid-cols-3 gap-3 mt-6">
                                {sessions.map((session) => (<SessionRecord key={session.id} session={session} />))}
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <span className="flex-1" />

            <Footer />
        </div>
    )
}