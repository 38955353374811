import { SessionQuestion } from "@/types/session";
import { Separator } from "@/components/ui/separator";

import { Button } from "@/components/ui/button";
import MCQuestionCard from "./MCQuestionCard";

export default function FluidView({
    title,
    questions,
    onChange,
    onSubmit,
}: {
    title: string,
    questions: SessionQuestion[],
    onChange: (qId: string, choiceIds: string[]) => void,
    onSubmit: () => void,
}) {


    return (
        <div className="mt-4">
            <div className="text-center">
                <h1 className="mb-4 text-xl font-bold text-black">{title}</h1>
                <Separator />
            </div>

            <div className="mt-6 min-h-[320px] w-full">
                <div className="motion-preset-slide-right mt-4 flex h-full flex-1 flex-col gap-12">
                    {questions.map((question, i) => (
                        <MCQuestionCard
                            key={question.id}
                            index={i}
                            id={question.id}
                            question={question.question}
                            image={question.media ? question.media.url : null}
                            choices={question.choices}
                            onAnswer={(qId, choiceId) => onChange(qId, [choiceId])}
                        />
                    ))}
                </div>

                <div className="mt-6 flex justify-end">
                    <Button size="lg" onClick={onSubmit}>
                        Submit & Exit
                    </Button>
                </div>
            </div>
        </div>
    );
}
