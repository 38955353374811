import { getResponseDetails } from "@/lib/data/studio/response.service";
import { Question } from "@/types/question";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { CheckIcon, Cross2Icon } from "@radix-ui/react-icons";
import { Badge } from "@/components/ui/badge";
import { ResponseWithMetadata } from "@/types/response";
import UserAvatar from "@/components/UserAvatar";
import { IdCardIcon } from "lucide-react";
import { format } from "date-fns";
import { humanDateFormat } from "@/lib/utils";

export default function ResponseDetailsPage() {
  const { responseId } = useParams();
  const [response, setResponse] = useState<ResponseWithMetadata | null>(null);
  const [orderedQuestions, setOrderedQuestions] = useState<Question[]>([]);
  const [durationFormatted, setDurationFormatted] = useState("");

  useEffect(() => {
    const load = async () => {
      const { response, questions } = await getResponseDetails(responseId!);
      const orderedQuestions: Question[] = response.questions_order.map(
        (id: string) => {
          return questions?.find((q: Question) => q.id === id)!;
        },
      );
      setOrderedQuestions(orderedQuestions);
      setResponse(response);
      if (response.duration > 60 * 60) {
        // more than an hour
        setDurationFormatted(`${Math.floor(response.duration / 60 / 60)} hours ${Math.floor((response.duration / 60) % 60)} minutes`);
      } else if (response.duration < 60) {
        // less than a minute
        setDurationFormatted(`${Math.floor(response.duration)} seconds`);
      } else {
        setDurationFormatted(`${Math.floor(response.duration / 60)} minutes`);
      }
    };
    load();
  }, [responseId]);

  if (!response) {
    return (
      <div>loading...</div>
    );
  }

  return (
    <>
      <div className="flex flex-col gap-4 px-4 lg:px-8 lg:min-w-3xl lg:max-w-3xl lg:mx-auto mt-4">
        <div className="flex flex-col gap-2 rounded-lg border bg-white p-4">
          <h4 className="text-gray-600 text-sm">
            Reviewing response by
          </h4>
          <div className="flex items-center gap-2">
            <UserAvatar url={response.participant.profile.avatar_url} name={response.participant.name} />
            <div>
              <p className="font-bold text-xl">{response.participant.name}</p>
              <p className="text-muted-foreground text-sm">{response.participant.profile.email}</p>
            </div>
          </div>
          <div className="text-muted-foreground text-sm px-12 flex items-center gap-2">
            <IdCardIcon className="size-4" />
            {response.participant.code}
          </div>
        </div>
        <div className="flex flex-col gap-4 rounded-lg border bg-white p-6 shadow">
          <h1 className="text-2xl font-bold text-gray-800">{response.quiz.title}</h1>
          <p className="text-gray-600">{response.quiz.description}</p>

          <div className="mt-4 flex flex-wrap gap-4">
            <div className="flex-1 rounded-lg border bg-gray-50 p-4 flex flex-col">
              <div className="text-3xl font-bold text-gray-800">
                {response.score}/{response.max_score}
              </div>
              <span className="flex-1" />
              <p className="text-sm text-gray-600">Score</p>
            </div>

            <div className="flex-1 rounded-lg border bg-gray-50 p-4 flex flex-col items-stretch">
              <div className="text-xl text-gray-800">
                {/* {secondsToMinutes(response.duration)} <span className="text-muted-foreground text-xs">minutes</span> */}
                {durationFormatted}
              </div>
              <span className="flex-1" />
              <p className="text-sm text-gray-600">Duration</p>
            </div>

            <div className="flex-1 rounded-lg border bg-gray-50 p-4 flex flex-col">
              <div className="text-sm font-medium text-gray-800">
                {response.is_completed ? format(new Date(response.is_completed), "HH:mm aaa") : ""}
              </div>
              <div className="text-sm font-medium text-gray-800">
                {response.is_completed ? format(new Date(response.is_completed), "dd LLL") : ""}
              </div>
              <span className="flex-1" />
              <p className="text-sm text-gray-600">Completed</p>
            </div>
          </div>

          <div className="mt-4 flex flex-wrap gap-2">
            <Badge variant="secondary">
              {response.questions_order.length} questions
            </Badge>
            {response.is_completed ? (
              <Badge variant="secondary" className="bg-green-100 text-green-800">
                Completed
              </Badge>
            ) : (
              <Badge variant="secondary" className="bg-yellow-100 text-yellow-800">
                {response.state}
              </Badge>
            )}
            <Badge variant="outline">started{" "}
              {format(new Date(response.created_at), humanDateFormat)}
            </Badge>
          </div>
        </div>

        <div className="flex flex-col gap-2">
          {orderedQuestions.map((q, index) => (
            <QuestionResponseRecord
              key={index}
              question={q}
              userResponse={
                response.selected_choices?.find((c) => c.qId === q.id)
                  ?.choiceIds[0]
              }
            />
          ))}
        </div>
        <div className="h-12" />
      </div>
    </>
  );
}

const QuestionResponseRecord = ({
  question,
  userResponse,
}: {
  question: Question;
  userResponse: string | null | undefined;
}) => {
  const isCorrect = question.correct_answer_id === userResponse;
  const hasAnswered = userResponse !== undefined && userResponse !== null;

  return (
    <div className="flex flex-col gap-2 rounded-lg border bg-white px-4 py-4">
      <h4 className="font-bold">Q: {question.question}</h4>
      {question.choices.map((c) => (
        <div key={c.id} className="flex items-center gap-2">
          <div
            className={`flex w-[90%] items-center gap-2 rounded-lg border px-4 py-2 lg:min-w-90 ${c.id === question.correct_answer_id ? "border-green-700" : ""
              } ${isCorrect && c.id === question.correct_answer_id
                ? "bg-green-700 text-white"
                : ""
              } ${!isCorrect && c.id === userResponse ? "bg-red-700 text-white shadow" : ""} `}
          >
            <label>{c.content}</label>
          </div>
          {isCorrect && c.id === question.correct_answer_id && (
            <CheckIcon className="size-6 text-green-700" />
          )}
          {!isCorrect && c.id === userResponse && (
            <Cross2Icon className="size-6 text-red-700" />
          )}
        </div>
      ))}

      {hasAnswered ? (
        <div className="mt-2 text-sm">
          {isCorrect ? (
            <span className="text-green-600">Correct answer!</span>
          ) : (
            <span className="text-red-600">Incorrect answer</span>
          )}
        </div>
      ) : (
        <div className="mt-2 text-sm text-gray-500">
          No answer provided
        </div>
      )}
    </div>
  );
};
