import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useState } from "react";
import { Group } from "@/types/participant";

type Props = {
    group: Group | null,
    isOpen: boolean,
    onOpenChange: (isOpen: boolean) => void,
    onSubmit: ({ id, name }: { id: string | undefined, name: string }) => Promise<void>;
}

export default function GroupDialog({ onSubmit, isOpen, onOpenChange, group }: Props) {
    const [editingGroup] = useState<boolean>(group ? true : false);
    const [newGroupName, setNewGroupName] = useState(group?.name ?? "");

    return (
        <Dialog open={isOpen} onOpenChange={onOpenChange}>
            {/* <DialogTrigger asChild>
                <Button variant="outline" size="sm" className="rounded-full ml-2">
                    <PlusIcon className="h-3 w-3 mr-1" /> New Group
                </Button>
            </DialogTrigger> */}
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>
                        {editingGroup ? "Edit Group" : "Create New Group"}
                    </DialogTitle>
                </DialogHeader>
                <div className="space-y-4 py-4">
                    <div className="space-y-2">
                        <Label htmlFor="group-name">Group Name</Label>
                        <Input
                            id="group-name"
                            placeholder="Enter group name"
                            value={newGroupName}
                            onChange={(e) => { setNewGroupName(e.target.value) }}
                        />
                    </div>
                    <div className="flex justify-end gap-2">
                        <Button
                            variant="outline"
                            onClick={() => {
                                onOpenChange(false)
                            }}
                        >
                            Cancel
                        </Button>
                        <Button onClick={() => onSubmit({ id: group?.id, name: newGroupName })}>
                            {editingGroup ? "Update" : "Create"}
                        </Button>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    );
}