import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogTrigger } from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { Question } from "@/types/question";
import { cn, deleteMedia, uploadImage } from "@/lib/utils";
import { ImageIcon, PlusIcon, TrashIcon } from "@radix-ui/react-icons";
import { ChangeEvent, useRef, useState, KeyboardEvent } from "react";
import { toast } from "sonner";
import { useCurrentWorkspaceId } from "@/hooks/use-current-ws";

type Props = { 
  question: Question, 
  disabled: boolean;
  onChange: (q: Question) => void
}

export default function MCQuestionForm({ question, onChange, disabled }: Props) {
    const workspaceId = useCurrentWorkspaceId()
    const [currentFocusedChoice, setCurrentFocusedChoice] = useState<number>(0);
    const [correctAnswerIndex, setCorrectAnswerIndex] = useState<number>(
        question.choices.findIndex((c) => c.id === question.correct_answer_id),
    );
    const choicesRef = useRef<HTMLInputElement[]>([]);
    const [errors, _] = useState({
        question: null,
        choices: null,
        answer: null
    })

    const addNewChoice = () => {
        // limit to 4 choices for MCQ, 2 for True/False
        if (
            (question.type === "mcq" && question.choices.length === 4) ||
            (question.type === "truefalse" && question.choices.length === 2)
        )
            return;

        question.choices.splice(currentFocusedChoice + 1, 0, {
            id: crypto.randomUUID(),
            content: "",
        });

        onChange({
            ...question,
            choices: [...question.choices],
        });

        // focus on the new choice input
        setTimeout(() => {
            setCurrentFocusedChoice((prev) => prev + 1);
            choicesRef.current[currentFocusedChoice + 1].focus();
        }, 0);
    };

    const handleChoiceContentChange = (index: number, content: string) => {
        question.choices[index].content = content;
        let correctAnswerId = question.correct_answer_id;
        if (correctAnswerIndex === index) {
            correctAnswerId = question.choices[index].id;
        }

        onChange({ ...question, choices: question.choices, correct_answer_id: correctAnswerId });
    };

    const setCorrectAnswer = (index: number, choiceId: string) => {
        question.correct_answer_id = choiceId;
        setCorrectAnswerIndex(index);
        onChange({ ...question, correct_answer_id: question.correct_answer_id });
    };

    const removeChoice = (index: number) => {
        // For True/False questions, don't allow removing choices
        if (question.type === "truefalse") return;

        onChange({
            ...question,
            choices: question.choices.filter((_, i) => i !== index),
        });
    };

    const handleImageUpload = async (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files || e.target.files.length === 0) return;

        const file = e.target.files[0];
        const fileExt = file.name.split('.').pop();
        const id = crypto.randomUUID();
        const fileName = `${workspaceId}/${question.id}/${id}.${fileExt}`;

        // Upload the new image
        const imageUrl = await uploadImage("logos", fileName, file);
        if (imageUrl) {
            onChange({ ...question, media: { type: file.type, url: imageUrl, id } });
        } else {
            toast.error("Failed to upload image");
        }
    };

    const handleRemoveMedia = async () => {
        if (question.media) {
            await deleteMedia(question.media?.url);
            onChange({ ...question, media: null });
        }
    };

    const handleFormNavigation = (e: KeyboardEvent<HTMLFormElement>) => {
        switch (e.key) {
            case "Enter":
                e.preventDefault();
                addNewChoice();
                break;
            case "ArrowDown":
                e.preventDefault();
                if (currentFocusedChoice < question.choices.length - 1) {
                    setCurrentFocusedChoice((prev) => prev + 1);
                    choicesRef.current[currentFocusedChoice + 1].focus();
                }
                break;
            case "ArrowUp":
                e.preventDefault();
                if (currentFocusedChoice > 0) {
                    choicesRef.current[currentFocusedChoice - 1].focus();
                    setCurrentFocusedChoice((prev) => prev - 1);
                }
                break;
            default:
                // do nothing
                break;
        }
    };

    return (
        <form
            id={question.id}
            className="mt-4 mb-6 px-6"
            onSubmit={(e) => e.preventDefault()}
            onKeyDown={handleFormNavigation}
        >
            <div>
                <label className="mb-2 block text-sm font-bold text-gray-700">
                    Question
                </label>
                <textarea
                    className="w-full resize-auto rounded-lg border px-3 py-2 focus:ring-2 focus:ring-blue-500 focus:outline-none"
                    value={question.question}
                    onChange={(e) =>
                        onChange({ ...question, question: e.target.value })
                    }
                    rows={2}
                    required
                    disabled={disabled}
                />
                {errors.question && <p className="text-red-500">{errors.question}</p>}
                <div className="mt-4">
                    <div className="flex items-center justify-between">
                        <Label className="block text-sm font-bold text-gray-700">
                            Question Image (Optional)
                        </Label>
                        {question.media && (
                            <Button
                                variant="ghost"
                                size="sm"
                                className="text-red-500 hover:text-red-700"
                                onClick={handleRemoveMedia}
                                disabled={disabled}
                            >
                                Remove Image
                            </Button>
                        )}
                    </div>

                    {question.media && (
                        <div className="mt-2 relative">
                            <img
                                src={question.media.url}
                                alt="Question image"
                                className="max-h-64 rounded-lg border object-contain"
                            />
                        </div>
                    )}
                    <div className="mt-2">
                        <label
                            htmlFor={`image-upload-${question.id}`}
                            className={cn("flex cursor-pointer items-center justify-center gap-2 rounded-lg border border-dashed border-gray-300 p-4 hover:bg-gray-50", disabled ? "cursor-not-allowed" : "")}
                        >
                            <ImageIcon className="h-5 w-5 text-gray-400" />
                            <span className="text-sm text-gray-500">Upload an image</span>
                        </label>
                        <input
                            id={`image-upload-${question.id}`}
                            type="file"
                            accept="image/*"
                            className="hidden"
                            onChange={handleImageUpload}
                            disabled={disabled}
                        />
                    </div>
                </div>
            </div>
            <div className="py-3">
                <label className="mb-2 block text-sm font-bold text-gray-700">
                    Choices
                </label>
                {errors.choices && <p className="text-red-500">{errors.choices}</p>}
                {errors.answer && <p className="text-red-500">{errors.answer}</p>}
                
                <div className={cn('flex justify-between', question.type === 'mcq' ? 'flex-col' : 'flex-row flex-wrap')}>
                    {question.choices.map((choice, index) => (
                        <div className="mb-2 flex items-center gap-2" key={index}>
                            <input
                                type="radio"
                                name="correctAnswer"
                                className=""
                                checked={question.correct_answer_id === choice.id}
                                onChange={() => setCorrectAnswer(index, choice.id)}
                                disabled={disabled}
                                readOnly={question.type === "truefalse" || disabled}
                            />
                            <input
                                type="text"
                                ref={(el) => {
                                    if (el && choicesRef.current) {
                                        choicesRef.current[index] = el;
                                    }
                                }}
                                onClick={() => setCurrentFocusedChoice(index)}
                                className="flex-1 rounded-lg border px-3 py-2 focus:ring-2 focus:ring-blue-500 focus:outline-none"
                                value={choice.content}
                                onChange={(e) => {
                                    handleChoiceContentChange(index, e.target.value);
                                }}
                                placeholder={`Choice ${index + 1}`}
                                disabled={disabled}
                                readOnly={question.type === "truefalse"}
                            />
                            {question.type === 'mcq' && (
                                <AlertDialog>
                                    <AlertDialogTrigger asChild>
                                        <button className="ml-2 hover:cursor-not-allowed" disabled={disabled}>
                                            <TrashIcon className="text-gray-400 hover:text-red-700" />
                                        </button>
                                    </AlertDialogTrigger>
                                    <AlertDialogContent>
                                        <AlertDialogHeader>
                                            <AlertDialogTitle>Are you sure?</AlertDialogTitle>
                                            <AlertDialogDescription>
                                                This action cannot be undone, if you proceed, the choice
                                                will be removed from our servers and that will hinder the
                                                responses and scores of the quiz.
                                            </AlertDialogDescription>
                                        </AlertDialogHeader>
                                        <AlertDialogFooter>
                                            <AlertDialogCancel>Cancel</AlertDialogCancel>
                                            <AlertDialogAction
                                                className="bg-red-500"
                                                onClick={() => removeChoice(index)}
                                            >
                                                Confirm
                                            </AlertDialogAction>
                                        </AlertDialogFooter>
                                    </AlertDialogContent>
                                </AlertDialog>
                            )}
                        </div>
                    ))}
                </div>

                {question.type === 'mcq' && (<div className="mt-4 mr-8 flex flex-row-reverse">
                    <Button
                        className={cn('border',
                            question.choices.length === 4
                                ? "cursor-not-allowed"
                                : "cursor-pointer"
                        )}
                        variant={"secondary"}
                        onClick={addNewChoice}
                        disabled={question.choices.length === 4}
                        aria-label="Add answer"
                    >
                        <PlusIcon className="h-4 w-4" />
                        Add choice
                    </Button>

                </div>
                )}
            </div>
        </form>
    )
}