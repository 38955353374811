import { Button } from "@/components/ui/button";
import { QuizSession } from "@/types/session";
import { Link } from "react-router";

export default function SessionEndScreen({ session }: { session: QuizSession }) {
  return (
    <div>
      <div className="rounded-lg border bg-white p-8">
        <h1 className="mb-4 text-3xl font-bold text-black">Quiz Completed!</h1>
        <p className="mt-6 text-lg">Best wishes for success and excellence</p>
        <p className="text-muted-foreground mt-12">
          You have reached the end of quiz. You can safely close this page.
        </p>
      </div>

      <div className="flex items-center justify-center mt-12">
        <Button asChild>
          <Link to={`/quiz/${session.quiz_id}`}>Go back to Quiz</Link>
        </Button>
      </div>
    </div>
  );
}
