import { Button } from "@/components/ui/button";
import UserAvatar from "@/components/UserAvatar";
import { ParticipantWithMetadata } from "@/types/participant";
import { CopyIcon } from "@radix-ui/react-icons";
import { ColumnDef } from "@tanstack/react-table";
import { toast } from "sonner";

export type User = {
  id: string;
  profile: {
    name: string;
    email: string;
    avatar_url?: string;
  };
  role: string;
};

export const StudentColumns: ColumnDef<ParticipantWithMetadata>[] = [
  {
    accessorKey: "name",
    header: "Name"
  },
  {
    id: "code",
    accessorKey: "code",
    header: "Code",
    cell: (row) => {
      const code = row.getValue<string>();
      return (
        <div className="inline-flex gap-1 items-center">
          <span className="min-w-max">{code}</span>
          <Button variant={"ghost"} size="icon" onClick={() => {
            navigator.clipboard.writeText(code)
            toast.info('Copied to clipboard')
          }}>
            <CopyIcon />
          </Button>
        </div>
      )
    }
  },
  {
    id: "groupId",
    accessorKey: "group.id",
    header: "",
    enableHiding: true,
    cell: () => { }
  },
  {
    id: "group",
    accessorKey: "group",
    header: "Group",
    cell: (row) => {
      const group = row.getValue<{ id: string, name: string }>();
      if (!group) {
        return '-'
      }
      return (
        <div className="inline-flex gap-1 items-center">
          <span className="min-w-max">{group.name}</span>
        </div>
      )
    }
  },
  {
    id: "profile",
    accessorFn: (row) => row,
    header: "User",
    cell: (row) => {
      const user = row.getValue<User>();
      if (!user.profile) {
        return null;
      }
      return (
        <div className="inline-flex items-center gap-2">
          <UserAvatar
            size="sm"
            name={user.profile.name}
            url={user.profile.avatar_url}
          />
          <span className="text-lg">{user.profile.name}</span>
        </div>
      );
    },
  },
  {
    accessorKey: "profile.email",
    header: "Email",
  },
];
