import { supabase } from "@/lib/supabase";
import { Quiz } from "@/types/quiz";

export async function getQuiz(id: string, workspaceId: string): Promise<Quiz> {
  // ensure that the quiz belongs to the workspace
  const { data, error } = await supabase
    .from("quizs")
    .select("*, responses:responses(count), questions_count:questions(count)")
    .eq("id", id)
    .eq('workspace_id', workspaceId)
    .returns<Quiz>()
    .single();

  if (error) throw error;

  return data;
}

export async function listQuizzes(workspaceId: string) {
  const { data, error } = await supabase
    .from("quizs")
    .select(
      "id, title, description, config, created_at, is_public, questions_count:questions_quiz_id_fkey(count), responses:responses(count)",
    )
    .eq("workspace_id", workspaceId)
    .returns<Quiz[]>();

  if (error) throw error;
  return data;
}

export async function listQuizzesWithQuestions(workspaceId: string) {
  const { data, error } = await supabase
    .from("quizs")
    .select(
      "id, title, description, config, created_at, is_public, questions_count:questions_quiz_id_fkey(count), responses:responses(count)",
    )
    .eq("workspace_id", workspaceId)
    .returns<Quiz[]>().order('created_at', { ascending: false });

  if (error) throw error;
  return data;
}
