import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Separator } from "@/components/ui/separator";
import WorkspaceInfoCard from "@/components/WorkspaceInfoCard";
import { useCurrentWorkspace } from "@/hooks/use-current-ws";
import { Quiz } from "@/types/quiz";
import { Workspace } from "@/types/workspace";
import { supabase } from "@/lib/supabase";
import { uploadImage } from "@/lib/utils";
import QuizStartScreen from "@/routes/quiz/QuizStartScreen";
import { useWorkspaceStore } from "@/store/workspace.store";
import { CameraIcon, MagicWandIcon } from "@radix-ui/react-icons";
import { ChangeEvent, useRef } from "react";
import { toast } from "sonner";

export default function BrandSettings() {
  const workspace = useCurrentWorkspace();
  const { updateWorkspace } = useWorkspaceStore();
  const orgLogoInputRef = useRef<HTMLInputElement>(null);

  if (!workspace) {
    return <div>Loading...</div>;
  }

  const exampleQuiz: Quiz = {
    id: "example-id",
    title: "Example Quiz",
    description: "This is an example quiz description",
    is_public: true,
    config: {
      mode: "focused",
      timer: {
        mode: "off",
        seconds: 0,
      },
      shuffleQuestions: false,
      shuffleChoices: false,
      revealFinalScore: false,
      maxAttempts: 1,
      timeWindow: {
        start: new Date().getTime(),
        duration: 10,
      },
    },
    responses: [{ count: 0 }],
    questions_count: [{ count: 0 }],
    workspace: workspace,
    workspace_id: workspace.id,
    created_at: new Date().getDate().toString(),
    updated_at: new Date().getDate().toString(),
  };

  const saveToDB = async (newWorkspace: Workspace) => {
    updateWorkspace(workspace.id, newWorkspace);
    const { error } = await supabase
      .from("workspaces")
      .update({
        name: newWorkspace.name,
        description: newWorkspace.description,
        logo: newWorkspace.logo,
        updated_at: new Date().toISOString(),
      })
      .eq("id", workspace.id);
    if (error) {
      console.error(error);
      toast.error("Failed to update workspace branding", {
        description: error.message,
      });
      return;
    }
    toast.success("Workspace brand saved successfully");
  };

  const handleNameChange = async (e: ChangeEvent<HTMLInputElement>) => {
    workspace.name = e.target.value;
    saveToDB(workspace);
  };

  const handleDescriptionChange = async (e: ChangeEvent<HTMLInputElement>) => {
    workspace.description = e.target.value;
    saveToDB(workspace);
  };

  const handleImageUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (!e.target.files) return;

    const file = orgLogoInputRef.current?.files?.[0];
    if (!file) return;
    let filename = orgLogoInputRef.current?.files?.[0]?.name;
    filename = `${workspace.id}/logo.${filename?.split(".").pop()}`;
    const logoUrl = await uploadImage("logos", filename, file);
    workspace.logo = logoUrl ?? null;
    await saveToDB(workspace);
    toast("Logo updated successfully");
  };

  return (
    <section className="flex flex-1 flex-col gap-4">
      <div className="flex flex-col">
        <div className="px-3 pt-4">
          <div className="inline-flex items-center gap-2">
            <MagicWandIcon />
            <h4 className="text-lg leading-loose font-bold">
              Workspace Branding Settings
            </h4>
          </div>
          <p className="px-6 text-sm text-neutral-600">
            Design the look and feel of your quizzes.
          </p>
        </div>
        <Separator className="mt-4" />
        <div className="flex flex-col lg:flex-row h-screen w-full gap-4">
          <section className="mt-4 flex w-full lg:w-1/2 flex-col px-8">
            <h4 className="text-sm leading-7 font-semibold">Workspace Info</h4>
            <p className="text-muted-foreground text-xs">
              Brand your quizzes with your logo and Workspace name.
            </p>
            <div className="mt-4 flex items-center gap-4 rounded-lg border bg-white p-4">
              <button
                onClick={() => orgLogoInputRef.current?.click()}
                className="flex h-24 w-32 content-center items-center justify-center rounded-full border bg-neutral-400 text-center text-xs"
              >
                {workspace.logo ? (
                  <img
                    src={workspace.logo}
                    className="h-24 w-24 rounded-full"
                  />
                ) : (
                  <CameraIcon className="h-6 w-6 text-white" />
                )}
              </button>
              <div className="flex w-full flex-col gap-3">
                <div className="flex w-full flex-col gap-1">
                  <Label className="text-muted-foreground text-xs">Title</Label>
                  <Input
                    name="orgName"
                    className="w-full"
                    placeholder="e.g: Cairo University, Company name, Team name"
                    defaultValue={workspace.name}
                    onChange={handleNameChange}
                  />
                </div>
                <div className="flex w-full flex-col gap-1">
                  <Label className="text-muted-foreground text-xs">
                    Description
                  </Label>
                  <Input
                    name="orgName"
                    placeholder="Department of Business Administration, Marketing Team, etc."
                    className="w-full"
                    defaultValue={workspace.description ?? ""}
                    onChange={handleDescriptionChange}
                  />
                </div>
              </div>
              <input
                ref={orgLogoInputRef}
                type="file"
                onChange={handleImageUpload}
                className="hidden"
              />
            </div>
          </section>
          <span className="lg:flex-1" />
          <div className="flex w-full lg:w-1/2 flex-col items-start gap-4 border-t lg:border-l p-4">
            <h5 className="text-muted-foreground text-sm">Preview</h5>

            <div className="rounded bg-white p-4 shadow">
              <WorkspaceInfoCard
                id={workspace.id}
                name={workspace.name}
                description={workspace.description}
                logo={workspace.logo}
              />
            </div>
            <QuizStartScreen quiz={exampleQuiz} onStart={() => { }} canStart={true} afterSchedule={false} />
          </div>
        </div>
      </div>
    </section>
  );
}
