import { Button } from "@/components/ui/button";
import { Link } from "react-router";

export default function TermsOfService() {
  const lastUpdated = "March 4, 2025";

  return (
    <div className="min-h-screen flex flex-col flex-1 container py-12">
      <div className="max-w-3xl mx-auto">
        <h1 className="text-4xl font-bold mb-4">Terms of Service</h1>
        <p className="text-gray-500 mb-8">Last Updated: {lastUpdated}</p>

        <section className="prose prose-gray max-w-none">
          <p className="text-lg">
            Welcome to QuizBase. These Terms of Service govern your use of our website, products, and services.
            By accessing or using QuizBase, you agree to be bound by these Terms. If you disagree with any part of the terms,
            you may not access our services.
          </p>

          <h2 className="text-2xl font-semibold mt-8 mb-4">1. Account Terms</h2>
          <p>
            You are responsible for maintaining the security of your account and password.
            QuizBase cannot and will not be liable for any loss or damage from your failure to comply with this security obligation.
          </p>
          <p>
            You are responsible for all content posted and activity that occurs under your account.
          </p>
          <p>
            You may not use our service for any illegal or unauthorized purpose nor may you, in the use of the Service,
            violate any laws in your jurisdiction.
          </p>

          <h2 className="text-2xl font-semibold mt-8 mb-4">2. Service Terms</h2>
          <p>
            Your use of QuizBase is at your sole risk. The service is provided on an "as is" and "as available" basis.
          </p>
          <p>
            You understand that QuizBase uses third-party vendors and hosting partners to provide the necessary hardware,
            software, networking, storage, and related technology required to run the Service.
          </p>
          <p>
            You must not modify, adapt or hack the Service or modify another website so as to falsely imply that it is
            associated with the Service, QuizBase, or any other QuizBase service.
          </p>

          <h2 className="text-2xl font-semibold mt-8 mb-4">3. Content Ownership</h2>
          <p>
            You retain ownership of any intellectual property rights that you hold in content that you submit to QuizBase.
          </p>
          <p>
            When you upload content to QuizBase, you grant us a worldwide license to use, host, store, reproduce, modify,
            create derivative works, communicate, publish, publicly perform, publicly display and distribute such content.
          </p>
          <p>
            You represent and warrant that you have all the rights, power, and authority necessary to grant the rights
            granted herein to any content that you submit.
          </p>

          <h2 className="text-2xl font-semibold mt-8 mb-4">4. Payment Terms</h2>
          <p>
            The Service is billed on a subscription basis. You will be billed in advance on a recurring and periodic basis.
          </p>
          <p>
            QuizBase reserves the right to change the fees upon thirty (30) days notice. Such notice may be provided at any
            time by posting the changes to the QuizBase site or via email.
          </p>

          <h2 className="text-2xl font-semibold mt-8 mb-4">5. Cancellation and Termination</h2>
          <p>
            You are solely responsible for properly canceling your account. An email or phone request to cancel your account
            is not considered cancellation. You can cancel your account at any time by clicking on the Account Settings link
            in the user interface.
          </p>
          <p>
            All of your content will be immediately deleted from the Service upon cancellation. This information cannot be recovered
            once your account is cancelled.
          </p>
          <p>
            QuizBase, in its sole discretion, has the right to suspend or terminate your account and refuse any and all current or
            future use of the Service for any reason at any time.
          </p>

          <h2 className="text-2xl font-semibold mt-8 mb-4">6. Modifications to the Service</h2>
          <p>
            QuizBase reserves the right at any time and from time to time to modify or discontinue, temporarily or permanently,
            the Service (or any part thereof) with or without notice.
          </p>
          <p>
            QuizBase shall not be liable to you or to any third party for any modification, suspension or discontinuance of the Service.
          </p>

          <h2 className="text-2xl font-semibold mt-8 mb-4">7. Limitation of Liability</h2>
          <p>
            You expressly understand and agree that QuizBase shall not be liable for any direct, indirect, incidental, special,
            consequential or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data or
            other intangible losses.
          </p>
          <p>
            In no event shall QuizBase or our suppliers be liable for any damages (including, without limitation, damages for loss of
            data or profit, or due to business interruption) arising out of the use or inability to use the materials on QuizBase's
            website, even if QuizBase or a QuizBase authorized representative has been notified orally or in writing of the possibility
            of such damage.
          </p>

          <h2 className="text-2xl font-semibold mt-8 mb-4">8. Governing Law</h2>
          <p>
            These Terms shall be governed and construed in accordance with the laws applicable in the jurisdiction where QuizBase
            is headquartered, without regard to its conflict of law provisions.
          </p>
          <p>
            Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any
            provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms
            will remain in effect.
          </p>

          <h2 className="text-2xl font-semibold mt-8 mb-4">9. Changes to Terms</h2>
          <p>
            QuizBase reserves the right, at our sole discretion, to update, change or replace any part of these Terms of Service
            by posting updates and changes to our website. It is your responsibility to check our website periodically for changes.
          </p>
          <p>
            Your continued use of or access to our website or the Service following the posting of any changes to these Terms of
            Service constitutes acceptance of those changes.
          </p>

          <div className="mt-12 flex space-x-4">
            <Link to="/">
              <Button variant="outline">Back to Home</Button>
            </Link>
            <Link to="/privacy">
              <Button variant="outline">Privacy Policy</Button>
            </Link>
          </div>
        </section>
      </div>
    </div>
  );
}
