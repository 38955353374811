import { supabase } from "@/lib/supabase";
import { Response, ResponseWithMetadata } from "@/types/response";
import { Question } from "@/types/question";

export type ResponsesFilter = { workspaceId?: string, quizId?: string, userName?: string, date?: Date };

export async function listResponses(filters: ResponsesFilter, opts?: { sortBy?: { field: string, direction: 'asc' | 'desc' } }) {
  let q = supabase
    .from("responses")
    .select(
      "id, quiz_id, current_question_index, state, reason, is_completed, duration, score, max_score, participant_id, created_at, quiz:quizs(id, title, workspace_id), participant:participants(*), profile:profiles(*)",
    )
  if (filters.workspaceId) {
    q = q.eq("quiz.workspace_id", filters.workspaceId).not("quiz", "is", null);
  }
  if (filters.quizId && filters.quizId != "all") {
    q = q.eq("quiz_id", filters.quizId);
  }
  if (filters.userName && filters.userName != "") {
    q.or(`name.ilike.%${filters.userName}%,code.ilike.%${filters.userName}%`, { referencedTable: "participants" }).not("participants", "is", null);
  }
  if (filters.date) {
    q = q.gte("created_at", filters.date.toISOString());
  }

  if (opts && opts.sortBy) {
    q = q.order(opts.sortBy.field, { ascending: opts.sortBy.direction === 'asc' });
  } else {
    q = q.order("is_sompleted", { ascending: false });
  }

  const { data, error } = await q.returns<ResponseWithMetadata[]>();
  if (error) throw error;

  return data;
}

export async function getResponseMeta(responseId: string) {
  const { data, error } = await supabase
    .from("responses")
    .select(
      "id, quiz_id, participant_id, created_at, quiz:quizs(id, title), user:responses_user_id_fkey(*), participant:responses_participant_id_fkey(*, profile:participants_profile_id_fkey(id,name,email,avatar_url))",
    )
    .eq("id", responseId)
    .returns<Response[]>()
    .single();

  if (error) throw error;

  return data;
}

export async function getResponseDetails(responseId: string) {
  const { data, error } = await supabase
    .from("responses")
    .select(
      "id, quiz_id, current_question_index, duration, is_completed, state, score, max_score, user_id, created_at, questions_order, selected_choices, quiz:quizs(id, title, description), participant:responses_participant_id_fkey(*, profile:participants_profile_id_fkey(id,name,email,avatar_url))",
    )
    .eq("id", responseId)
    .returns<ResponseWithMetadata[]>()
    .single();
  if (error) throw error;

  // load questions
  const { data: questions } = await supabase
    .from("questions")
    .select("id, index, question, type, choices, media, bank_id, points, correct_answer_id, created_at, updated_at")
    .in("id", data.questions_order)
    .returns<Question[]>()

  return { response: data, questions };
}
