import MCQuestionCard from "./MCQuestionCard";
import { useState } from "react";
import { Button } from "@/components/ui/button";
import { ReloadIcon } from "@radix-ui/react-icons";
import { SessionQuestion } from "@/types/session";

export default function FocusedView({
    index,
    question,
    isLastElement,
    isLoading,
    onChange,
}: {
    index: number,
    question: SessionQuestion,
    isLastElement: boolean,
    isLoading: boolean,
    onChange: (qId: string, choiceIds: string[]) => void,
}) {
    const [choiceIds, setChoiceIds] = useState<string[]>([]);
    const handleAnswer = (_: string, choiceId: string) => {
        setChoiceIds([choiceId]);
    }

    const handleSubmitAnswer = () => {
        if (isLoading) return;

        onChange(question.id, choiceIds);
        // clear selected choices
        setChoiceIds([]);
    }

    return (
        <div className="mt-4 rounded-lg ">
            <div className="mt-6 min-h-[320px] w-full">

                <div className="motion-preset-slide-right mt-4 h-full flex-1">
                    <MCQuestionCard
                        id={question.id}
                        index={index}
                        question={question.question}
                        image={question.media ? question.media.url : null}
                        choices={question.choices}
                        onAnswer={handleAnswer} />


                    <div className="mt-6 flex justify-end place-self-end">
                        {!isLoading && (
                            <Button
                                disabled={!choiceIds.length}
                                onClick={handleSubmitAnswer}
                                className="px-12"
                                size={"lg"}
                            >
                                {isLastElement ? "Submit & Exit" : "Next"}
                            </Button>
                        )}

                        {isLoading && (
                            <Button disabled className="px-12" size={"lg"}>
                                <span className="animate-spin">
                                    <ReloadIcon />
                                </span>{" "}
                                Loading...
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}