import { FormEvent, useState } from "react";
import { supabase } from "../lib/supabase";
import { Button } from "./ui/button";
import { useNavigate, useSearchParams } from "react-router";
import { Separator } from "./ui/separator";
import { toast } from "sonner";
import { UpdateIcon } from "@radix-ui/react-icons";

export default function Login() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const redirectTo = searchParams.has("next")
    ? import.meta.env.VITE_BASE_URL + searchParams.get("next")
    : import.meta.env.VITE_BASE_URL;
  const handleLogin = async (e: FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    const { error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });
    if (error) {
      setIsLoading(false);
      toast.error("Failed to login", {
        description: error.message,
      });
      return;
    }
    setIsLoading(false);
    const destination = searchParams.get("next") || "/";
    navigate(destination);
  };

  const handleGoogleLogin = async () => {
    await supabase.auth.signInWithOAuth({
      provider: "google",
      options: {
        redirectTo: redirectTo,
      },
    });
  };

  return (
    <div className="flex min-h-screen items-center justify-center bg-gray-50 p-4">
      <div className="flex w-full max-w-4xl overflow-hidden rounded-xl shadow-lg">
        {/* Left side - Login form */}
        <div className="w-full md:w-1/2 bg-white p-8">
          <div className="mx-auto max-w-sm">
            <div>
              <h2 className="text-center text-2xl font-bold text-gray-900">
                Sign in to QuizBase
              </h2>
              <p className="mt-2 text-center text-sm text-gray-600">
                Access your quizzes, analytics, and teaching tools
              </p>
            </div>
            <div className="mt-8 space-y-6">
              <Button
                onClick={handleGoogleLogin}
                className="w-full items-center gap-4"
              >
                <img
                  src="/google.png"
                  alt="Google"
                  className="h-4 w-4 rounded-full"
                />
                Login with Google
              </Button>
              <Separator />
              <p className="text-muted-foreground text-center text-xs hidden">
                Sign using email and password
              </p>
              <form className="space-y-6 hidden" onSubmit={handleLogin}>
                <div className="flex flex-col gap-3 -space-y-px">
                  <div>
                    <label
                      htmlFor="email"
                      className="text-sm leading-9 text-gray-500"
                    >
                      Email
                    </label>
                    <input
                      id="email"
                      name="email"
                      type="email"
                      required
                      className="bg-zinc-100 relative block w-full appearance-none rounded-lg border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:ring-indigo-500 focus:outline-none sm:text-sm"
                      placeholder="e.g: john@doe.com"
                      autoComplete="username"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="password"
                      className="flex items-center text-sm leading-9 text-gray-500"
                    >
                      Password
                      <span className="flex-1" />
                      <span className="text-muted-foreground cursor-not-allowed text-xs">
                        Forget your password?
                      </span>
                    </label>
                    <input
                      id="password"
                      name="password"
                      type="password"
                      required
                      className="bg-zinc-100 relative block w-full appearance-none rounded-lg border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:ring-indigo-500 focus:outline-none sm:text-sm"
                      placeholder="*******"
                      autoComplete="current-password"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                </div>

                <div>
                  <Button
                    type="submit"
                    className="w-full bg-neutral-900"
                    disabled={isLoading}
                  >
                    {!isLoading ? (
                      "Sign in"
                    ) : (
                      <>
                        <UpdateIcon className="spin" /> loading...
                      </>
                    )}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
        
        {/* Right side - Brand section */}
        <div className="hidden md:block md:w-1/2 bg-black text-white p-8">
          <div className="flex flex-col justify-center h-full">
            <h1 className="text-3xl font-bold mb-4">QuizBase</h1>
            <p className="text-lg mb-8">The professional quiz platform for educators</p>
            <div className="space-y-4">
              <div className="flex items-start">
                <svg className="h-5 w-5 mr-2 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                </svg>
                <p className="text-sm">Create engaging assessments with our intuitive quiz builder</p>
              </div>
              <div className="flex items-start">
                <svg className="h-5 w-5 mr-2 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                </svg>
                <p className="text-sm">Access detailed analytics to track student performance</p>
              </div>
              <div className="flex items-start">
                <svg className="h-5 w-5 mr-2 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                </svg>
                <p className="text-sm">Share quizzes with your students via secure links</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
