import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";
import { supabase } from "./supabase";
import { toast } from "sonner";
import { QuizRequiredValues } from "@/types/quiz";
import { addDays, format } from "date-fns";

export const humanDateFormat = "dd LLL, hh:mm aaa";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function shuffleArray<T>(source: T[]): T[] {
  const shuffled = [...source];
  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * source.length);
    [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  }
  return shuffled;
}

export const formatTime = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
};

export const deleteMedia = async (url: string) => {
  const { error: deleteError } = await supabase.storage
    .from("logos")
    .remove([url]);

  if (deleteError) {
    console.debug({ deleteError });
    toast.error("Failed to delete image", {
      description: deleteError.message,
    });
    return;
  }
}

export const uploadImage = async (
  bucket: string,
  filename: string,
  file: File,
) => {
  const { data, error: uploadError } = await supabase.storage
    .from(bucket)
    .upload(filename, file, {
      cacheControl: "3600",
      upsert: true,
    });

  if (uploadError) {
    console.debug({ uploadError });
    toast.error("Failed to upload image", {
      description: uploadError.message,
    });
    return null;
  }

  return import.meta.env.VITE_ASSETS_URL + data.fullPath;
};

export const buildQuizObject = (
  title: string,
  description: string,
  workspaceId: string,
): QuizRequiredValues => {
  return {
    title,
    description,
    workspace_id:workspaceId,
    is_public: true,
    config: {
      mode: "focused",
      timer: {
        mode: "total",
        minutes: 30,
        seconds: 30 * 60,
      },
      timeWindow: {
        start: addDays(new Date(), 1).getTime(),
        duration: 10,
      },
      shuffleQuestions: true,
      shuffleChoices: true,
      revealFinalScore: false,
      maxAttempts: 1,
    }
  };
};

export const formatDuration = (seconds: number) => {
  if (typeof seconds !== 'number' || isNaN(seconds)) {
    return '-'
  }
  let duration = ''
  if (seconds > 60 * 60) {
    // more than an hour
    duration = `${Math.floor(seconds / 60 / 60)}h ${Math.floor((seconds / 60) % 60)}min`;
  } else if (seconds < 60) {
    // less than a minute
    duration = `${Math.floor(seconds)}s`;
  } else {
    duration = `${Math.floor(seconds / 60)}min`;
  }
  return duration
}

export const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  return format(date, 'MMM d, yyyy');
};

export const formatSchedule = (timeWindow?: { start: number; duration: number }) => {
  if (!timeWindow || !timeWindow.start) return 'Not scheduled';

  const startDate = new Date(timeWindow.start);
  return format(startDate, 'MMM d, yyyy h:mm a');
};