import { differenceInSeconds } from "date-fns";
import { supabase } from "@/lib/supabase";
import { shuffleArray } from "@/lib/utils";
import { QuizSession, SessionQuestion } from "@/types/session";
import { Participant } from "@/types/participant";

export const createSession = async (
    quizId: string,
    participant?: Participant
): Promise<{ data: { id: string } | null, error: { code: string, message: string } | null }> => {
    const user = await supabase.auth.getUser()
    if (!user.data.user) {
        return { data: null, error: { code: "401", message: "not authorized" } }
    }
    const payload = {
        intent: 'start',
        quizId,
        data: {
            userId: user.data.user.id,
            participantId: participant?.id ?? null
        }
    }

    try {
        const { data: id, error } = await supabase.functions.invoke('sessions', { body: JSON.stringify(payload) })

        if (error) {
            const body = await new Response(error.context.body).json()
            return { data: null, error: { code: body.code, message: body.message } }
        }
        return { data: { id }, error: null }
    } catch (err) {
        console.log({ err })
        return { data: null, error: { code: "500", message: "failed to start session" } }
    }

}

export const loadNextQuestion = async (session: QuizSession): Promise<QuizSession> => {
    // prepare next question
    const { data, error } = await supabase.from("questions").select("id,question,type,choices,media").eq("id", session.questions_order[session.current_question_index]).returns<SessionQuestion>().single();
    if (error) {
        return Promise.reject({ code: "500", message: "failed to get question" });
    }

    session.questions = [data]

    if (session.quiz.config.shuffleChoices) {
        session.questions[0].choices = shuffleArray(session.questions[0].choices)
    }

    return session
}

export const loadSession = async (
    quizId: string,
    sessionId: string,
): Promise<QuizSession> => {
    const auth = await supabase.auth.getUser();
    const userId = auth.data.user?.id;
    if (!userId) {
        return Promise.reject({ code: "401", message: "not authorized" });
    }

    const payload = {
        intent: 'load',
        quizId,
        data: {
            sessionId,
        }
    }

    try {
        const { data, error } = await supabase.functions.invoke('sessions', { body: JSON.stringify(payload) })
        if (error) {
            const body = await new Response(error.context.body).json()
            return Promise.reject({ code: body.code, message: body.message })
        }
        return data
    } catch (err) {
        console.log({ err })
        return Promise.reject({ code: "500", message: "failed to load session" })
    }
};

export const submitAnswer = async (session: QuizSession, qId: string, choiceIds: string[]): Promise<QuizSession> => {
    // save user's answer, update session and check if grading should be done
    // return new question if quiz is in focused mode and not completed

    // set session state to inProgress
    session.state = 'inProgress';

    // calculate seconds spent from last updatedAt
    const questionDuration = differenceInSeconds(new Date(), session.updated_at);

    // Update the total duration of the session
    session.duration = (session.duration || 0) + questionDuration;

    // check if user has answered the question before, if so update the choice
    const existingChoice = session.selected_choices.findIndex((c) => c.qId === qId);
    if (existingChoice > -1) {
        session.selected_choices[existingChoice].duration = questionDuration;
        session.selected_choices[existingChoice].choiceIds = choiceIds;
    } else {
        session.selected_choices.push({ qId, choiceIds, duration: questionDuration });
    }

    session.current_question_index = session.current_question_index + 1;

    // update the session
    session.updated_at = new Date()
    const { error } = await supabase
        .from("responses")
        .update({
            selected_choices: session.selected_choices,
            state: session.state,
            current_question_index: session.current_question_index,
            duration: session.duration, // Store the total elapsed time
            updated_at: "now()"
        })
        .eq("id", session.id);

    if (error) {
        throw error
    }

    return session
}

export type EndQuizRequest = {
    reason: string
    sessionId: string
    duration: number // number of seconds the quiz took
}

export const endSession = async (session: QuizSession, state: string, reason?: string) => {
    session.is_completed = new Date();
    session.state = state;
    session.reason = reason ?? null;

    // If we already have a duration from previous submissions, use that as a base
    // and add the time since last update. Otherwise, calculate from the start.
    if (session.duration) {
        const timeSinceLastUpdate = differenceInSeconds(new Date(), new Date(session.updated_at));
        session.duration += timeSinceLastUpdate;
    } else {
        session.duration = differenceInSeconds(new Date(), new Date(session.created_at));
    }

    session.updated_at = new Date();

    // load session questions
    const { data: questions, error } = await supabase.from("questions").select("id,question,correct_answer_id,type,points").in("id", session.questions_order);
    if (error) {
        return Promise.reject({ code: "500", message: "failed to grade quiz" });
    }

    // grade the quiz
    session.selected_choices.map((c) => {
        if (c.choiceIds.length && c.choiceIds[0] === questions.find((q) => q.id === c.qId)?.correct_answer_id) {
            session.score += 1;
        }
    });

    const { error: saveError } = await supabase
        .from("responses")
        .update({
            current_question_index: session.current_question_index,
            selected_choices: session.selected_choices,
            is_completed: "NOW()",
            state: session.state,
            reason: session.reason,
            score: session.score,
            duration: session.duration,
            updated_at: "NOW()"
        })
        .eq("id", session.id)
        .single()


    if (saveError) {
        return Promise.reject({ code: "500", message: "failed to end session" });
    }

    return session
}

export const getSession = async (id: string) => {
    return await supabase
        .from("responses")
        .select("*")
        .eq("id", id)
        .returns<QuizSession[]>()
        .single()
}

export const forceEndSession = async (sessionId: string, state: string, reason?: string) => {
    const { data: session, error } = await getSession(sessionId)
    if (error) {
        return Promise.reject({ code: "500", message: "failed to end session" });
    }
    session.is_completed = new Date();
    session.state = state;
    session.reason = reason ?? null;

    // If we already have a duration from previous submissions, use that as a base
    // and add the time since last update. Otherwise, calculate from the start.
    if (session.duration) {
        const timeSinceLastUpdate = differenceInSeconds(new Date(), new Date(session.updated_at));
        session.duration += timeSinceLastUpdate;
    } else {
        session.duration = differenceInSeconds(new Date(), new Date(session.created_at));
    }

    session.updated_at = new Date();

    // load quiz questions
    const { data: questions, error: questionsError } = await supabase.from("questions").select("id,question,type,correct_answer_id,points").in("id", session.questions_order);
    if (questionsError) {
        return Promise.reject({ code: "500", message: "failed to grade quiz" });
    }

    // grade the quiz
    session.selected_choices.map((c: { qId: string, choiceIds: string[] }) => {
        if (c.choiceIds.length && c.choiceIds[0] === questions.find((q) => q.id === c.qId)?.correct_answer_id) {
            session.score += 1;
        }
    });

    const { error: saveError } = await supabase
        .from("responses")
        .update({
            currentElementIndex: session.current_question_index,
            selectedChoices: session.selected_choices,
            is_completed: "NOW()",
            state: session.state,
            reason: session.reason,
            score: session.score,
            duration: session.duration,
            updated_at: session.updated_at.toISOString()
        })
        .eq("id", session.id)
        .single()


    if (saveError) {
        return Promise.reject({ code: "500", message: "failed to end session" });
    }

    return session
}

export const endQuiz = async ({ reason, sessionId, duration }: EndQuizRequest) => {
    return await supabase
        .from("responses")
        .update({ is_completed: "now()", reason, duration })
        .eq("id", sessionId)
        .single()
}

export async function listPreviousSessions(userId: string): Promise<QuizSession[]> {
    const { data, error } = await supabase
        .from("responses")
        .select("id,quiz_id,user_id,created_at,duration,is_completed,state,quiz:quizs(id,title,description,config)")
        .eq("user_id", userId)
        .returns<QuizSession[]>()
        .order("created_at", { ascending: false });
    if (error) {
        console.error(error);
        return [];
    }

    return data;
}