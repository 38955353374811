import { useEffect, useState } from "react";
import { supabase } from "@/lib/supabase";
import { ResponseWithMetadata } from "@/types/response";
import { CheckIcon, Cross2Icon } from "@radix-ui/react-icons";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Skeleton } from "@/components/ui/skeleton";
import WorkspaceInfoCard from "@/components/WorkspaceInfoCard";
import { format } from "date-fns";
import { humanDateFormat } from "@/lib/utils";
import { useNavigate, useParams } from "react-router";
import { Question } from "@/types/question";
import { QuizSession } from "@/types/session";

export default function QuizScoreReview() {
  const { quizId, sessionId } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [response, setResponse] = useState<ResponseWithMetadata | null>(null);
  const [orderedQuestions, setOrderedQuestions] = useState<Question[]>([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchSessionDetails = async () => {
      try {
        // Check if user is authenticated
        const { data: { user } } = await supabase.auth.getUser();
        if (!user) {
          navigate("/login?next=" + window.location.pathname);
          return;
        }

        // Fetch the session details
        const { data, error } = await supabase
          .from("responses")
          .select("*, quiz:quizs(id,title,description,questions_count:questions_quiz_id_fkey(count),config,is_public,workspace_id,workspace:workspaces(id,name,description,logo)), profile:profiles(id,name,email,avatar_url)")
          .eq("id", sessionId!)
          .eq("quiz_id", quizId!)
          .eq("user_id", user.id)
          .returns<ResponseWithMetadata[]>()
          .single();

        if (error) {
          console.error("Error fetching session:", error);
          setError("Failed to load session details");
          setIsLoading(false);
          return;
        }

        if (!data) {
          setError("Session not found");
          setIsLoading(false);
          return;
        }

        // Check if the quiz allows score review
        if (!data.quiz.config.revealFinalScore) {
          setError("Score review is not enabled for this quiz");
          setIsLoading(false);
          return;
        }

        // Check if the session is completed
        if (data.state !== "completed") {
          setError("This quiz session is not completed yet");
          setIsLoading(false);
          return;
        }

        // Order questions according to the session's element order
        // load from db
        const { data: questions, error: questionsError } = await supabase
          .from("questions")
          .select("*")
          .in("id", data.questions_order)
          .returns<Question[]>();
        if (questionsError) {
          console.error("Error fetching questions:", questionsError);
          setError("Failed to load questions");
          setIsLoading(false);
          return;
        }

        // const orderedQuestions: Question[] = data.elementsOrder.map(
        //   (id: string) => {
        //     return data.quiz.questions.find((q: Question) => q.id === id)!;
        //   }
        // );

        setOrderedQuestions(questions);
        setResponse(data);
        setIsLoading(false);
        document.title = `${data.quiz.title} - Score Review`;
      } catch (err) {
        console.error("Error in fetchSessionDetails:", err);
        setError("An unexpected error occurred");
      } finally {
        setIsLoading(false);
      }
    };

    fetchSessionDetails();
  }, [quizId, sessionId, navigate]);

  if (isLoading) {
    return (
      <div className="min-h-screen bg-gray-50 p-8">
        <div className="mx-auto flex max-w-3xl flex-col gap-3 rounded-lg bg-white p-8 shadow">
          <Skeleton className="h-4 w-[250px] rounded-xl" />
          <Skeleton className="h-4 w-[250px] rounded-xl" />
          <span className="h-12" />
          <Skeleton className="h-4 w-[250px] rounded-xl" />
          <Skeleton className="h-4 w-[250px] rounded-xl" />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gray-50 p-8">
        <div className="mx-auto flex max-w-3xl flex-col items-center justify-center gap-4 rounded-lg bg-white p-8 shadow text-center">
          <h2 className="text-xl font-bold text-gray-800">Error</h2>
          <p className="text-gray-600">{error}</p>
          <Button onClick={() => navigate(`/quiz/${quizId}`)}>
            Return to Quiz
          </Button>
        </div>
      </div>
    );
  }

  if (!response) {
    return (
      <div className="min-h-screen bg-gray-50 p-8">
        <div className="mx-auto flex max-w-3xl flex-col items-center justify-center gap-4 rounded-lg bg-white p-8 shadow text-center">
          <h2 className="text-xl font-bold text-gray-800">Session Not Found</h2>
          <p className="text-gray-600">The requested quiz session could not be found.</p>
          <Button onClick={() => navigate(`/quiz/${quizId}`)}>
            Return to Quiz
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 p-8">
      <div className="mx-auto flex max-w-3xl flex-col gap-8">
        <WorkspaceInfoCard
          id={response.quiz.workspace_id}
          name={response.quiz.workspace.name}
          description={response.quiz.workspace.description}
          logo={response.quiz.workspace.logo}
        />

        <div className="flex flex-col gap-4 rounded-lg border bg-white p-6 shadow">
          <h1 className="text-2xl font-bold text-gray-800">{response.quiz.title}</h1>
          <p className="text-gray-600">{response.quiz.description}</p>

          <div className="mt-4 flex flex-wrap gap-4">
            <div className="flex-1 rounded-lg border bg-gray-50 p-4">
              <div className="text-3xl font-bold text-gray-800">
                {response.score}/{response.max_score}
              </div>
              <p className="text-sm text-gray-600">Score</p>
            </div>

            <div className="flex-1 rounded-lg border bg-gray-50 p-4">
              <div className="text-3xl font-bold text-gray-800">
                {response.duration}
              </div>
              <p className="text-sm text-gray-600">Seconds</p>
            </div>

            <div className="flex-1 rounded-lg border bg-gray-50 p-4">
              <div className="text-sm font-medium text-gray-800">
                {format(new Date(response.created_at), humanDateFormat)}
              </div>
              <p className="text-sm text-gray-600">Completed</p>
            </div>
          </div>

          <div className="mt-4 flex flex-wrap gap-2">
            <Badge variant="secondary">
              {response.questions_order.length} questions
            </Badge>
            {response.state === "completed" ? (
              <Badge variant="secondary" className="bg-green-100 text-green-800">
                Completed
              </Badge>
            ) : (
              <Badge variant="secondary" className="bg-yellow-100 text-yellow-800">
                {response.state}
              </Badge>
            )}
          </div>
        </div>

        <div className="flex flex-col gap-4">
          <h2 className="text-xl font-semibold text-gray-800">Question Review</h2>

          {orderedQuestions.map((question, index) => (
            <QuestionReviewCard
              key={question.id}
              question={question}
              index={index}
              userResponse={
                response.selected_choices?.find((c) => c.qId === question.id)
                  ?.choiceIds[0]
              }
            />
          ))}
        </div>

        <div className="flex justify-center mt-8">
          <Button onClick={() => navigate(`/quiz/${quizId}`)}>
            Return to Quiz
          </Button>
        </div>
      </div>
    </div>
  );
}

function QuestionReviewCard({
  question,
  index,
  userResponse,
}: {
  question: Question;
  index: number;
  userResponse: string | null | undefined;
}) {
  const isCorrect = question.correct_answer_id === userResponse;
  const hasAnswered = userResponse !== undefined && userResponse !== null;

  return (
    <div className="flex flex-col gap-3 rounded-lg border bg-white p-6 shadow-sm">
      <div className="flex items-center gap-2">
        <span className="flex h-6 w-6 items-center justify-center rounded-full bg-gray-200 text-sm font-medium">
          {index + 1}
        </span>
        <h3 className="text-lg font-medium text-gray-800">{question.question}</h3>
      </div>

      <div className="mt-2 space-y-2">
        {question.choices.map((choice) => (
          <div key={choice.id} className="flex items-center gap-2">
            <div
              className={`flex flex-1 items-center gap-2 rounded-lg border px-4 py-3 ${choice.id === question.correct_answer_id
                ? "border-green-500"
                : hasAnswered && choice.id === userResponse
                  ? "border-red-500"
                  : "border-gray-200"
                } ${choice.id === question.correct_answer_id
                  ? "bg-green-50"
                  : hasAnswered && choice.id === userResponse && choice.id !== question.correct_answer_id
                    ? "bg-red-50"
                    : "bg-white"
                }`}
            >
              <span className="text-gray-800">
                {choice.content}
              </span>
            </div>

            {choice.id === question.correct_answer_id && (
              <CheckIcon className="h-5 w-5 text-green-600" />
            )}

            {hasAnswered && choice.id === userResponse && choice.id !== question.correct_answer_id && (
              <Cross2Icon className="h-5 w-5 text-red-600" />
            )}
          </div>
        ))}
      </div>

      {hasAnswered ? (
        <div className="mt-2 text-sm">
          {isCorrect ? (
            <span className="text-green-600">Correct answer!</span>
          ) : (
            <span className="text-red-600">Incorrect answer</span>
          )}
        </div>
      ) : (
        <div className="mt-2 text-sm text-gray-500">
          No answer provided
        </div>
      )}
    </div>
  );
}

export const QuizReviewContainer = ({ response, orderedQuestions }: { response: QuizSession, orderedQuestions: Question[] }) => {
  return (
    <div className="flex flex-col gap-4 mt-6">
      <div className="flex flex-col gap-4 rounded-lg border bg-white p-6 shadow">
        <h1 className="text-2xl font-bold text-gray-800">{response.quiz.title}</h1>
        <p className="text-gray-600">{response.quiz.description}</p>

        <div className="mt-4 flex flex-wrap gap-4">
          <div className="flex-1 rounded-lg border bg-gray-50 p-4">
            <div className="text-3xl font-bold text-gray-800">
              {response.score}/{response.max_score}
            </div>
            <p className="text-sm text-gray-600">Score</p>
          </div>

          <div className="flex-1 rounded-lg border bg-gray-50 p-4">
            <div className="text-3xl font-bold text-gray-800">
              {response.duration}
            </div>
            <p className="text-sm text-gray-600">Seconds</p>
          </div>

          <div className="flex-1 rounded-lg border bg-gray-50 p-4">
            <div className="text-sm font-medium text-gray-800">
              {format(new Date(response.created_at), humanDateFormat)}
            </div>
            <p className="text-sm text-gray-600">Completed</p>
          </div>
        </div>

        <div className="mt-4 flex flex-wrap gap-2">
          <Badge variant="secondary">
            {response.questions_order.length} questions
          </Badge>
          {response.state === "completed" ? (
            <Badge variant="secondary" className="bg-green-100 text-green-800">
              Completed
            </Badge>
          ) : (
            <Badge variant="secondary" className="bg-yellow-100 text-yellow-800">
              {response.state}
            </Badge>
          )}
        </div>
      </div>

      <div className="flex flex-col gap-4">
        <h2 className="text-xl font-semibold text-gray-800">Question Review</h2>

        {orderedQuestions.map((question, index) => (
          <QuestionReviewCard
            key={question.id}
            question={question}
            index={index}
            userResponse={
              response.selected_choices?.find((c) => c.qId === question.id)
                ?.choiceIds[0]
            }
          />
        ))}
      </div>
    </div>
  );
}