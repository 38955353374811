import { useEffect, useRef } from "react";
import { Outlet } from "react-router";
import { supabase } from "./lib/supabase";
import { useAuthStore } from "./store/auth.store";
import { useBootstrapStore } from "./store/bootstrap.store";
import { useWorkspaceStore } from "./store/workspace.store";

export default function RootLayout() {
  const { setUser } = useAuthStore();
  const { setWorkspaces, setCurrentWorkspace } = useWorkspaceStore();
  const { setIsLoading } = useBootstrapStore();
  // This a workaround to prevent the onAuthStateChange from firing twice on page load
  // context: when users switch to another tab and get back, the event fires and every thing rerenders
  const receivedFirstSignInEvent = useRef(false);

  useEffect(() => {
    supabase.auth.getSession().then((session) => {
      if (session.data.session) {
        setUser(session.data.session.user);
        setIsLoading(false);
      }
    });
    const { data } = supabase.auth.onAuthStateChange((event, session) => {
      if (event === "SIGNED_IN" && receivedFirstSignInEvent.current === false) {
        receivedFirstSignInEvent.current = true;
        setUser(session?.user ?? undefined);
      } else if (event === "SIGNED_OUT") {
        setUser(undefined);
        setWorkspaces([]);
        setCurrentWorkspace(-1);
      }
      setIsLoading(false);
    });

    return () => {
      data?.subscription.unsubscribe();
    };
  }, [setCurrentWorkspace, setIsLoading, setUser, setWorkspaces]);

  return (
    <Outlet />
  );
}
