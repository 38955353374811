import { NewQuestionRequest, Question, QuestionMedia } from "@/types/question";
import { supabase } from "@/lib/supabase";
import { PostgrestSingleResponse } from "@supabase/supabase-js";
import { toast } from "sonner";

export interface QuestionService {
    quizId: string | null;
    bankId: string | null;
    load({ limit }: { limit?: number }): Promise<PostgrestSingleResponse<Question[]>>;
    delete(id: string, media: QuestionMedia | null): Promise<{ error: Error | null }>;
    update(questionId: string, question: Question): Promise<{ error: Error | null }>
    updateIndexes(question: Question, otherQuestion: Question): Promise<{ error: Error | null; }>
    create(question: NewQuestionRequest): Promise<{ error: Error | null }>
    createMany(questions: NewQuestionRequest[]): Promise<{ error: Error | null }>
}

export class QuestionServiceImpl implements QuestionService {

    constructor(public bankId: string | null, public quizId: string | null) { }

    async updateIndexes(question: Question, otherQuestion: Question): Promise<{ error: Error | null; }> {
        await supabase
            .from('questions')
            .update({ index: question.index })
            .eq('id', question.id)

        await supabase
            .from('questions')
            .update({ index: otherQuestion.index })
            .eq('id', otherQuestion.id)

        return { error: null }
    }

    async load({ limit = 20 }: { limit?: number }): Promise<PostgrestSingleResponse<Question[]>> {

        let query = supabase.from("questions")
            .select('*')


        if (this.bankId) {
            query = query.eq('bank_id', this.bankId)
        } else {
            // as a security fallback, don't allow querying questions without quiz_id or bank_id
            query = query.eq('quiz_id', this.quizId ?? "")
        }


        return await query.limit(limit).order('index', { ascending: true }).overrideTypes<Question[]>()
    }

    async delete(id: string, media: QuestionMedia | null): Promise<{ error: Error | null }> {
        const promises = []
        promises.push(supabase
            .from("questions")
            .delete()
            .eq('id', id))

        promises.push(this.deleteQuestionMedia(id, media))

        const resp = await Promise.all(promises)
        return { error: resp[0]?.error as Error | null }
    }

    async update(questionId: string, question: Question): Promise<{ error: Error | null }> {
        return await supabase
            .from("questions")
            .update(question)
            .eq('id', questionId)
    }

    async create(question: NewQuestionRequest): Promise<{ error: Error | null }> {
        return await supabase
            .from("questions")
            .insert([question])
    }

    async createMany(questions: NewQuestionRequest[]): Promise<{ error: Error | null }> {
        return await supabase
            .from("questions")
            .insert(questions)
    }

    async deleteQuestionMedia(questionId: string, media: QuestionMedia | null) {
        if (!media) {
            return
        }
        const bucketName = 'logos';
        // Extract the path from the full URL
        const paths = []
        const urlParts = media.url.split('/');
        const pathInBucket = `${questionId}/image.${urlParts[urlParts.length - 1].split('.').pop()}`;
        paths.push(pathInBucket);

        try {
            const { error } = await supabase.storage
                .from(bucketName)
                .remove(paths);

            if (error) {
                console.error("Error deleting image:", error);
                toast.error("Failed to delete image");
            }
        } catch (error) {
            console.error("Error deleting image:", error);
        }
    };
}