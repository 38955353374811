import { Button } from "@/components/ui/button";
import { Link } from "react-router";

export default function PrivacyPolicy() {
  const lastUpdated = "March 1, 2025";

  return (
    <div className="min-h-screen flex flex-col flex-1 container py-12">
      <div className="max-w-3xl mx-auto">
        <h1 className="text-4xl font-bold mb-4">Privacy Policy</h1>
        <p className="text-gray-500 mb-8">Last Updated: {lastUpdated}</p>

        <section className="prose prose-gray max-w-none">
          <p className="text-lg">
            At QuizBase, we take your privacy seriously. This Privacy Policy explains how we collect, use, disclose,
            and safeguard your information when you use our platform. Please read this privacy policy carefully.
            If you do not agree with the terms of this privacy policy, please do not access the site.
          </p>

          <h2 className="text-2xl font-semibold mt-8 mb-4">Information We Collect</h2>

          <h3 className="text-xl font-medium mt-6 mb-3">Personal Data</h3>
          <p>
            We may collect personal identification information from Users in a variety of ways, including, but not limited to:
          </p>
          <ul className="list-disc pl-6 mb-4">
            <li>When registering on our platform</li>
            <li>Creating or editing your user profile</li>
            <li>Creating quizzes or educational content</li>
            <li>Participating in quizzes or assessments</li>
            <li>Subscribing to our service</li>
            <li>Responding to a survey or communication such as e-mail</li>
          </ul>
          <p>
            The personal information you provide may include your name, email address, phone number,
            company/organization name, job title, and payment information.
          </p>

          <h3 className="text-xl font-medium mt-6 mb-3">Usage Data</h3>
          <p>
            We may also collect information on how the platform is accessed and used. This usage data may include
            information such as your computer's Internet Protocol (IP) address, browser type, browser version,
            the pages of our platform that you visit, the time and date of your visit, the time spent on those pages,
            unique device identifiers, and other diagnostic data.
          </p>

          <h3 className="text-xl font-medium mt-6 mb-3">Quiz and Assessment Data</h3>
          <p>
            When you create or participate in quizzes, we collect the content of those quizzes, responses,
            scores, and analytics related to performance.
          </p>

          <h2 className="text-2xl font-semibold mt-8 mb-4">How We Use Your Information</h2>
          <p>We may use the information we collect from you to:</p>
          <ul className="list-disc pl-6 mb-4">
            <li>Provide, operate, and maintain our platform</li>
            <li>Improve, personalize, and expand our platform</li>
            <li>Understand and analyze how you use our platform</li>
            <li>Develop new products, services, features, and functionality</li>
            <li>Process transactions and send related information including confirmations and invoices</li>
            <li>Send administrative information such as product announcements, security updates, or support and service messages</li>
            <li>Respond to comments, questions, and requests and provide customer service and support</li>
            <li>Communicate with you about products, services, offers, promotions, and events, and provide other news or information about us and our partners</li>
            <li>Monitor and analyze trends, usage, and activities in connection with our platform</li>
            <li>Detect, prevent, and address technical issues, security breaches, and fraudulent transactions</li>
            <li>Comply with legal obligations</li>
          </ul>

          <h2 className="text-2xl font-semibold mt-8 mb-4">Data Retention</h2>
          <p>
            We will retain your personal information only for as long as is necessary for the purposes set out in this
            Privacy Policy. We will retain and use your information to the extent necessary to comply with our legal
            obligations, resolve disputes, and enforce our policies.
          </p>
          <p>
            If you wish to request that we delete your data, you can contact us at privacy@quizbase.com.
          </p>

          <h2 className="text-2xl font-semibold mt-8 mb-4">Data Security</h2>
          <p>
            We use administrative, technical, and physical security measures to help protect your personal information.
            While we have taken reasonable steps to secure the personal information you provide to us, please be aware
            that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission
            can be guaranteed against any interception or other type of misuse.
          </p>

          <h2 className="text-2xl font-semibold mt-8 mb-4">Cookies and Tracking Technologies</h2>
          <p>
            We use cookies and similar tracking technologies to track activity on our platform and hold certain information.
            Cookies are files with a small amount of data which may include an anonymous unique identifier.
            You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent.
          </p>
          <p>
            We use the following types of cookies:
          </p>
          <ul className="list-disc pl-6 mb-4">
            <li><strong>Essential cookies:</strong> Necessary for the operation of our platform</li>
            <li><strong>Analytical/performance cookies:</strong> Allow us to recognize and count the number of visitors and see how visitors move around our platform</li>
            <li><strong>Functionality cookies:</strong> Used to recognize you when you return to our platform</li>
            <li><strong>Targeting cookies:</strong> Record your visit to our platform, the pages you have visited and the links you have followed</li>
          </ul>

          <h2 className="text-2xl font-semibold mt-8 mb-4">Third-Party Service Providers</h2>
          <p>
            We may employ third-party companies and individuals to facilitate our platform, provide the platform on our behalf,
            perform platform-related services, or assist us in analyzing how our platform is used. These third parties have
            access to your personal information only to perform these tasks on our behalf and are obligated not to disclose
            or use it for any other purpose.
          </p>
          <p>
            Our third-party service providers include:
          </p>
          <ul className="list-disc pl-6 mb-4">
            <li>Cloud hosting and infrastructure providers</li>
            <li>Payment processors</li>
            <li>Analytics services</li>
            <li>Customer support services</li>
            <li>Email and communication services</li>
          </ul>

          <h2 className="text-2xl font-semibold mt-8 mb-4">Children's Privacy</h2>
          <p>
            Our platform is not intended for use by children under the age of 13. We do not knowingly collect personally
            identifiable information from children under 13. If you are a parent or guardian and you are aware that your
            child has provided us with personal information, please contact us so that we can take necessary actions.
          </p>

          <h2 className="text-2xl font-semibold mt-8 mb-4">Your Data Protection Rights</h2>
          <p>
            Depending on your location, you may have certain rights regarding your personal information, such as:
          </p>
          <ul className="list-disc pl-6 mb-4">
            <li>The right to access, update, or delete your information</li>
            <li>The right to rectification (to correct inaccurate data)</li>
            <li>The right to object to our processing of your personal data</li>
            <li>The right of restriction (to request that we restrict processing of your data)</li>
            <li>The right to data portability (to receive a copy of your data in a structured format)</li>
            <li>The right to withdraw consent</li>
          </ul>
          <p>
            If you would like to exercise any of these rights, please contact us at privacy@quizbase.com.
          </p>

          <h2 className="text-2xl font-semibold mt-8 mb-4">International Data Transfers</h2>
          <p>
            Your information, including personal data, may be transferred to and maintained on computers located outside
            of your state, province, country, or other governmental jurisdiction where the data protection laws may differ
            from those of your jurisdiction.
          </p>
          <p>
            If you are located outside the United States and choose to provide information to us, please note that we
            transfer the data, including personal data, to the United States and process it there.
          </p>

          <h2 className="text-2xl font-semibold mt-8 mb-4">Changes to This Privacy Policy</h2>
          <p>
            We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new
            Privacy Policy on this page and updating the "Last Updated" date at the top of this page. You are advised
            to review this Privacy Policy periodically for any changes.
          </p>

          <h2 className="text-2xl font-semibold mt-8 mb-4">Contact Us</h2>
          <p>
            If you have any questions about this Privacy Policy, please contact us:
          </p>
          <ul className="list-none pl-0 mb-4">
            <li>By email: privacy@quizbase.com</li>
            <li>By visiting our contact page: quizbase.com/contact</li>
            <li>By mail: QuizBase, 123 Tech Street, San Francisco, CA 94103, USA</li>
          </ul>
        </section>

        <div className="flex justify-center mt-10">
          <Link to="/">
            <Button className="mr-4">Back to Home</Button>
          </Link>
        </div>
      </div>
    </div>
  );
}
