import Header from "@/components/Header";
import Footer from "@/components/Footer";
import { Outlet } from "react-router";

export default function SiteLayout() {
    return (
        <section className="bg-white z-0">
            <Header />
            <main>
                <Outlet />
            </main>
            <Footer />
        </section>
    )
}