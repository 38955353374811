import { buttonVariants } from "@/components/ui/button";
import { ArrowRight } from "lucide-react";
import { Link, NavLink } from "react-router";
import "./homepage-animations.css";

export default function HomePage() {
  return (
    <>
      <section className="bg-white text-gray-800 relative overflow-hidden pb-10 z-10">
        <div className="container relative z-10">
          <div className="flex flex-col items-center text-center">
            <div className="mb-8">
              <div className="inline-block bg-gray-200 text-black px-3 py-1 text-sm font-medium rounded-full mb-4">
                Quiz creation reimagined
              </div>
              <h1 className="text-5xl md:text-6xl font-bold leading-tight mb-4">
                <span className="relative">Engage.</span> <span className="relative">Assess.</span> <span className="relative text-black">Excel.</span>
              </h1>
              <p className="text-xl text-gray-600 mb-6 max-w-2xl mx-auto">
                Create captivating quizzes that transform how university professors teach, assess, and engage students.
              </p>
              <div className="flex items-center justify-center gap-4">
                <Link
                  to="/studio/quizzes"
                  className={buttonVariants({ variant: "default", size: "lg", className: "bg-black text-white hover:bg-gray-900" })}
                >
                  Create quiz now
                  <ArrowRight className="ml-2 h-4 w-4 transition-transform group-hover:translate-x-1" />
                </Link>
              </div>
            </div>
            <div className="flex flex-col lg:flex-row gap-8 mt-8">
              <div className="relative w-full max-w-lg">
                <div className="bg-white rounded-lg overflow-hidden border border-gray-200">
                  <div className="p-2 border-b border-gray-200 bg-gray-50 flex items-center">
                    <div className="flex space-x-1.5">
                      <div className="w-3 h-3 rounded-full bg-red-500"></div>
                      <div className="w-3 h-3 rounded-full bg-yellow-500"></div>
                      <div className="w-3 h-3 rounded-full bg-green-500"></div>
                    </div>
                  </div>
                  <img
                    src="/homepage/quiz-question-example.png"
                    alt="Quiz Question Example"
                    className="w-full"
                  />
                </div>
              </div>
              <div className="relative w-full max-w-lg">
                <div className="bg-white rounded-lg overflow-hidden border border-gray-200">
                  <div className="p-2 border-b border-gray-200 bg-gray-50 flex items-center">
                    <div className="flex space-x-1.5">
                      <div className="w-3 h-3 rounded-full bg-red-500"></div>
                      <div className="w-3 h-3 rounded-full bg-yellow-500"></div>
                      <div className="w-3 h-3 rounded-full bg-green-500"></div>
                    </div>
                  </div>
                  <img
                    src="/homepage/Quiz Insights.png"
                    alt="Quiz Insights Dashboard"
                    className="w-full"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="solutions" className="py-16 bg-gradient-to-br from-zinc-900 to-black text-white">
        <div className="container">
          <div className="text-center mb-12">
            <h2 className="text-2xl md:text-3xl font-bold mb-4">Transforming Knowledge Assessment</h2>
            <p className="text-zinc-400 max-w-2xl mx-auto">
              QuizBase is designed specifically for university professors who need powerful assessment tools that are simple to use and deliver results.
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {/* Card 1 */}
            <div className="bg-zinc-900/80 rounded-lg p-6 border border-zinc-700 hover:border-zinc-500 transition-all">
              <div className="bg-zinc-800 rounded-full w-12 h-12 flex items-center justify-center mb-4">
                <svg className="h-6 w-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
                </svg>
              </div>
              <h3 className="text-xl font-bold mb-2">For University Professors</h3>
              <p className="text-zinc-400 mb-4">
                Create engaging assessments that keep university students motivated while providing valuable insights into their learning progress.
              </p>
              <ul className="space-y-2">
                <li className="flex items-start">
                  <svg className="h-5 w-5 text-zinc-500 mr-2 mt-0.5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className="text-zinc-400 text-sm">Interactive lecture activities</span>
                </li>
                <li className="flex items-start">
                  <svg className="h-5 w-5 text-zinc-500 mr-2 mt-0.5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className="text-zinc-400 text-sm">Course assessments and exam preparation</span>
                </li>
                <li className="flex items-start">
                  <svg className="h-5 w-5 text-zinc-500 mr-2 mt-0.5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className="text-zinc-400 text-sm">Academic performance tracking</span>
                </li>
              </ul>
            </div>

            {/* Card 2 */}
            <div className="bg-zinc-900/80 rounded-lg p-6 border border-zinc-700 hover:border-zinc-500 transition-all">
              <div className="bg-zinc-800 rounded-full w-12 h-12 flex items-center justify-center mb-4">
                <svg className="h-6 w-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"></path>
                </svg>
              </div>
              <h3 className="text-xl font-bold mb-2">For Academic Departments</h3>
              <p className="text-zinc-400 mb-4">
                Streamline department-wide assessments, standardize course evaluations, and improve teaching effectiveness across your department.
              </p>
              <ul className="space-y-2">
                <li className="flex items-start">
                  <svg className="h-5 w-5 text-zinc-500 mr-2 mt-0.5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className="text-zinc-400 text-sm">Standardized departmental assessments</span>
                </li>
                <li className="flex items-start">
                  <svg className="h-5 w-5 text-zinc-500 mr-2 mt-0.5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className="text-zinc-400 text-sm">Accreditation preparation</span>
                </li>
                <li className="flex items-start">
                  <svg className="h-5 w-5 text-zinc-500 mr-2 mt-0.5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className="text-zinc-400 text-sm">Faculty development</span>
                </li>
              </ul>
            </div>

            {/* Card 3 */}
            <div className="bg-zinc-900/80 rounded-lg p-6 border border-zinc-700 hover:border-zinc-500 transition-all">
              <div className="bg-zinc-800 rounded-full w-12 h-12 flex items-center justify-center mb-4">
                <svg className="h-6 w-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"></path>
                </svg>
              </div>
              <h3 className="text-xl font-bold mb-2">For Communities</h3>
              <p className="text-zinc-400 mb-4">
                Build engaging community activities, trivia nights, and knowledge-sharing experiences that bring people together.
              </p>
              <ul className="space-y-2">
                <li className="flex items-start">
                  <svg className="h-5 w-5 text-zinc-500 mr-2 mt-0.5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className="text-zinc-400 text-sm">Virtual team building</span>
                </li>
                <li className="flex items-start">
                  <svg className="h-5 w-5 text-zinc-500 mr-2 mt-0.5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className="text-zinc-400 text-sm">Social trivia events</span>
                </li>
                <li className="flex items-start">
                  <svg className="h-5 w-5 text-zinc-500 mr-2 mt-0.5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  <span className="text-zinc-400 text-sm">Knowledge sharing</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="py-16 bg-gradient-to-b from-white to-zinc-50">
        <div className="container">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
            <div>
              <img
                src="/homepage/Quiz Insights.png"
                className="rounded-lg border border-zinc-200"
                alt="QuizBase Analytics Dashboard"
              />
            </div>
            <div>
              <div className="inline-block bg-black text-white px-3 py-1 text-sm font-medium rounded-full mb-4">
                Why choose QuizBase?
              </div>
              <h2 className="text-3xl font-bold mb-6">Built for speed, simplicity, and security</h2>
              <p className="text-muted-foreground mb-8">
                QuizBase combines powerful assessment capabilities with an intuitive interface, making it the perfect choice for university professors and academic departments.
              </p>

              <div className="space-y-6">
                <div className="flex group">
                  <div className="mr-4 flex-shrink-0 relative">
                    <div className="bg-gradient-to-br from-purple-500 to-blue-500 rounded-full w-10 h-10 flex items-center justify-center transition-all duration-300 group-hover:scale-110">
                      <svg className="h-5 w-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z" className="group-hover:animate-pulse"></path>
                      </svg>
                      <div className="absolute inset-0 rounded-full bg-white opacity-0 group-hover:opacity-20 group-hover:animate-ping duration-1000"></div>
                    </div>
                  </div>
                  <div>
                    <h3 className="font-semibold mb-1">Lightning Fast Performance</h3>
                    <p className="text-muted-foreground text-sm">
                      Our platform is built for speed, with optimized loading times and responsive design that works seamlessly on any device, including smartphones and tablets.
                    </p>
                  </div>
                </div>

                <div className="flex group">
                  <div className="mr-4 flex-shrink-0 relative">
                    <div className="bg-gradient-to-br from-emerald-500 to-teal-500 rounded-full w-10 h-10 flex items-center justify-center transition-all duration-300 group-hover:scale-110">
                      <svg className="h-5 w-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" className="group-hover:animate-pulse"></path>
                      </svg>
                      <div className="absolute inset-0 rounded-full bg-white opacity-0 group-hover:opacity-20 group-hover:animate-ping duration-1000"></div>
                    </div>
                  </div>
                  <div>
                    <h3 className="font-semibold mb-1">Enterprise-Grade Security</h3>
                    <p className="text-muted-foreground text-sm">
                      Your data is protected with end-to-end encryption and secure authentication. We take privacy seriously.
                    </p>
                  </div>
                </div>

                <div className="flex group">
                  <div className="mr-4 flex-shrink-0 relative">
                    <div className="bg-gradient-to-br from-amber-500 to-orange-500 rounded-full w-10 h-10 flex items-center justify-center transition-all duration-300 group-hover:scale-110">
                      <svg className="h-5 w-5 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z" className="group-hover:animate-pulse"></path>
                      </svg>
                      <div className="absolute inset-0 rounded-full bg-white opacity-0 group-hover:opacity-20 group-hover:animate-ping duration-1000"></div>
                    </div>
                  </div>
                  <div>
                    <h3 className="font-semibold mb-1">Intuitive User Experience</h3>
                    <p className="text-muted-foreground text-sm">
                      Create professional quizzes in minutes with our drag-and-drop interface that works perfectly on smartphones and tablets. No technical skills required.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-12 bg-gradient-to-t from-zinc-50 to-white" id="features">
        <div className="container text-center">
          <h2 className="text-2xl leading-10 font-bold">Powerful Features</h2>
          <p className="text-muted-foreground mb-8">
            Everything you need to create engaging quizzes
          </p>

          <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3 mb-12">
            {/* Feature 1 */}
            <div className="flex flex-col rounded-lg border border-zinc-300 bg-white p-6 transition-all hover:border-zinc-400 text-left">
              <div className="mb-4 rounded-full bg-gradient-to-br from-purple-500 to-blue-500 p-3 w-12 h-12 flex items-center justify-center transition-all duration-300">
                <svg className="h-6 w-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"></path>
                </svg>
              </div>
              <h3 className="text-xl font-bold mb-2">Intuitive Question Builder</h3>
              <p className="text-muted-foreground">
                Create multiple choice, true/false, and open-ended questions with our drag-and-drop interface. Add images, videos, and code snippets to make your academic quizzes more engaging for students.
              </p>
            </div>

            {/* Feature 2 */}
            <div className="flex flex-col rounded-lg border border-zinc-300 bg-white p-6 transition-all hover:border-zinc-400 text-left">
              <div className="mb-4 rounded-full bg-gradient-to-br from-blue-500 to-indigo-500 p-3 w-12 h-12 flex items-center justify-center transition-all duration-300">
                <svg className="h-6 w-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"></path>
                </svg>
              </div>
              <h3 className="text-xl font-bold mb-2">Detailed Analytics</h3>
              <p className="text-muted-foreground">
                Track quiz performance with comprehensive analytics. See completion rates, average scores, and identify the most challenging questions to improve your course content and teaching methods.
              </p>
            </div>

            {/* Feature 3 */}
            <div className="flex flex-col rounded-lg border border-zinc-300 bg-white p-6 transition-all hover:border-zinc-400 text-left">
              <div className="mb-4 rounded-full bg-gradient-to-br from-emerald-500 to-teal-500 p-3 w-12 h-12 flex items-center justify-center transition-all duration-300">
                <svg className="h-6 w-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"></path>
                </svg>
              </div>
              <h3 className="text-xl font-bold mb-2">Secure Testing</h3>
              <p className="text-muted-foreground">
                Prevent academic dishonesty with randomized questions, time limits, and one-time access codes. Ensure the integrity of your university assessments with our advanced security features.
              </p>
            </div>

            {/* Feature 4 */}
            <div className="flex flex-col rounded-lg border border-zinc-300 bg-white p-6 transition-all hover:border-zinc-400 text-left">
              <div className="mb-4 rounded-full bg-gradient-to-br from-pink-500 to-rose-500 p-3 w-12 h-12 flex items-center justify-center transition-all duration-300">
                <svg className="h-6 w-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                </svg>
              </div>
              <h3 className="text-xl font-bold mb-2">Automated Grading</h3>
              <p className="text-muted-foreground">
                Save time with instant grading for multiple choice and true/false questions. Provide immediate feedback to students and export results in various formats compatible with university grading systems.
              </p>
            </div>

            {/* Feature 5 */}
            <div className="flex flex-col rounded-lg border border-zinc-300 bg-white p-6 transition-all hover:border-zinc-400 text-left">
              <div className="mb-4 rounded-full bg-gradient-to-br from-amber-500 to-orange-500 p-3 w-12 h-12 flex items-center justify-center transition-all duration-300">
                <svg className="h-6 w-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"></path>
                </svg>
              </div>
              <h3 className="text-xl font-bold mb-2">Rich Media Support</h3>
              <p className="text-muted-foreground">
                Enhance your quizzes with images, videos, and audio clips. Create more engaging and interactive learning experiences with multimedia content.
              </p>
            </div>

            {/* Feature 6 */}
            <div className="flex flex-col rounded-lg border border-zinc-300 bg-white p-6 transition-all hover:border-zinc-400 text-left">
              <div className="mb-4 rounded-full bg-gradient-to-br from-sky-500 to-cyan-500 p-3 w-12 h-12 flex items-center justify-center transition-all duration-300">
                <svg className="h-6 w-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 15a4 4 0 004 4h9a5 5 0 10-.1-9.999 5.002 5.002 0 10-9.78 2.096A4.001 4.001 0 003 15z"></path>
                </svg>
              </div>
              <h3 className="text-xl font-bold mb-2">Cloud Sync</h3>
              <p className="text-muted-foreground">
                Access your quizzes from anywhere with cloud synchronization. Work on your quizzes across devices and never worry about losing your progress.
              </p>
            </div>
          </div>

          <div className="mt-12">
            <h3 className="text-xl font-bold mb-6">See our features in action</h3>
            <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
              <div className="rounded-lg border border-zinc-300 bg-white overflow-hidden transition-all">
                <div className="p-6 text-left">
                  <h4 className="text-lg font-bold mb-2">Simple Question Builder</h4>
                  <p className="text-muted-foreground mb-4">
                    Create professional quizzes in minutes with our intuitive drag-and-drop interface. Add multiple choice, true/false, and open-ended questions with ease.
                  </p>
                </div>
                <img
                  src="/homepage/Shuffling & Randomness.png"
                  className="w-full border-t border-zinc-200"
                  alt="Question Builder Interface"
                />
              </div>

              <div className="rounded-lg border border-zinc-300 bg-white overflow-hidden transition-all">
                <div className="p-6 text-left">
                  <h4 className="text-lg font-bold mb-2">Comprehensive Response Analysis</h4>
                  <p className="text-muted-foreground mb-4">
                    Review detailed responses and analytics to understand how participants performed. Identify knowledge gaps and improve your content based on real data.
                  </p>
                </div>
                <img
                  src="/homepage/result review with wrong answer.png"
                  className="w-full border-t border-zinc-200"
                  alt="Response Analysis Dashboard"
                />
              </div>

              <div className="rounded-lg border border-zinc-300 bg-white overflow-hidden transition-all">
                <div className="p-6 text-left">
                  <h4 className="text-lg font-bold mb-2">Quiz Landing Page</h4>
                  <p className="text-muted-foreground mb-4">
                    Create professional landing pages for your quizzes with custom branding, instructions, and time limits.
                  </p>
                </div>
                <img
                  src="/homepage/Quiz Landing Page.png"
                  className="w-full border-t border-zinc-200"
                  alt="Quiz Landing Page"
                />
              </div>

              <div className="rounded-lg border border-zinc-300 bg-white overflow-hidden transition-all">
                <div className="p-6 text-left">
                  <h4 className="text-lg font-bold mb-2">Detailed Quiz Settings</h4>
                  <p className="text-muted-foreground mb-4">
                    Configure advanced settings for your quizzes including time limits, randomization, and access controls.
                  </p>
                </div>
                <img
                  src="/homepage/Quiz Settings.png"
                  className="w-full border-t border-zinc-200"
                  alt="Quiz Settings Interface"
                />
              </div>

              <div className="rounded-lg border border-zinc-300 bg-white overflow-hidden transition-all">
                <div className="p-6 text-left">
                  <h4 className="text-lg font-bold mb-2">Comprehensive Results</h4>
                  <p className="text-muted-foreground mb-4">
                    View detailed results and analytics to understand student performance and identify areas for improvement.
                  </p>
                </div>
                <img
                  src="/homepage/Result Overview.png"
                  className="w-full border-t border-zinc-200"
                  alt="Results Overview Dashboard"
                />
              </div>

              <div className="rounded-lg border border-zinc-300 bg-white overflow-hidden transition-all">
                <div className="p-6 text-left">
                  <h4 className="text-lg font-bold mb-2">Correct Answer Review</h4>
                  <p className="text-muted-foreground mb-4">
                    Students can review their answers and see detailed explanations for correct responses to enhance learning.
                  </p>
                </div>
                <img
                  src="/homepage/result review with correct answer.png"
                  className="w-full border-t border-zinc-200"
                  alt="Correct Answer Review"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-gradient-to-b from-white to-zinc-50 py-12" id="pricing">
        <div className="container text-center">
          <h2 className="text-2xl leading-10 font-bold">Pricing Plans</h2>
          <p className="text-muted-foreground mb-8">
            Choose the perfect plan based on your student enrollment needs
          </p>

          <div className="grid grid-cols-1 gap-8 md:grid-cols-3">
            {/* Free Tier */}
            <div className="flex flex-col rounded-lg border border-zinc-300 bg-white p-6 transition-all hover:border-zinc-400">
              <h3 className="text-xl font-bold">Free</h3>
              <div className="my-4">
                <span className="text-4xl font-bold">$0</span>
                <span className="text-muted-foreground"></span>
              </div>
              <p className="text-muted-foreground mb-6">Perfect for small classes</p>
              <ul className="mb-6 space-y-2 text-left">
                <li className="flex items-center">
                  <svg className="mr-2 h-5 w-5 text-black" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  Up to 30 monthly quiz sessions
                </li>
                <li className="flex items-center">
                  <svg className="mr-2 h-5 w-5 text-black" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  Unlimited students
                </li>
                <li className="flex items-center">
                  <svg className="mr-2 h-5 w-5 text-black" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  Basic analytics
                </li>
              </ul>
              <NavLink
                to="/login"
                className={buttonVariants({ variant: "outline", className: "mt-auto" })}
              >
                Get Started
              </NavLink>
            </div>

            {/* Pro Tier */}
            <div className="flex flex-col rounded-lg border-2 border-black bg-gradient-to-b from-white to-zinc-50 p-6 transition-all">
              <div className="-mt-8 mb-4 rounded-full bg-black px-3 py-1 text-sm font-medium text-white self-start">
                Popular
              </div>
              <h3 className="text-xl font-bold">Pro</h3>
              <div className="my-4">
                <span className="text-4xl font-bold">$10</span>
                <span className="text-muted-foreground">/month</span>
              </div>
              <p className="text-sm text-muted-foreground mb-1">or $100/year (save $20)</p>
              <p className="text-muted-foreground mb-6">Perfect for medium-sized classes</p>
              <ul className="mb-6 space-y-2 text-left">
                <li className="flex items-center">
                  <svg className="mr-2 h-5 w-5 text-black" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  Up to 200 monthly sessions
                </li>
                <li className="flex items-center">
                  <svg className="mr-2 h-5 w-5 text-black" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  Unlimited students
                </li>
                <li className="flex items-center">
                  <svg className="mr-2 h-5 w-5 text-black" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  Advanced analytics
                </li>
                <li className="flex items-center">
                  <svg className="mr-2 h-5 w-5 text-black" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  Priority support
                </li>
              </ul>
              <NavLink
                to="/login?plan=pro"
                className={buttonVariants({ variant: "default", className: "mt-auto bg-black hover:bg-black/90" })}
              >
                Get Started
              </NavLink>
            </div>

            {/* Team Tier */}
            <div className="flex flex-col rounded-lg border border-zinc-300 bg-white p-6 transition-all hover:border-zinc-400">
              <h3 className="text-xl font-bold">Institution</h3>
              <div className="my-4">
                <span className="text-4xl font-bold">Custom</span>
              </div>
              {/* <p className="text-sm text-muted-foreground mb-1">or $10/student/year (save $2)</p> */}
              <p className="text-muted-foreground mb-6">Perfect for universities and departments</p>
              <ul className="mb-6 space-y-2 text-left">
                <li className="flex items-center">
                  <svg className="mr-2 h-5 w-5 text-black" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  Unlimited quiz sessions
                </li>
                <li className="flex items-center">
                  <svg className="mr-2 h-5 w-5 text-black" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  Unlimited students
                </li>
                <li className="flex items-center">
                  <svg className="mr-2 h-5 w-5 text-black" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  Advanced analytics
                </li>
                <li className="flex items-center">
                  <svg className="mr-2 h-5 w-5 text-black" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  Priority support
                </li>
                <li className="flex items-center">
                  <svg className="mr-2 h-5 w-5 text-black" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                  </svg>
                  Custom branding
                </li>
              </ul>
              <NavLink
                to="/login?plan=institution"
                className={buttonVariants({ variant: "outline", className: "mt-auto" })}
              >
                Get Started
              </NavLink>
            </div>
          </div>
        </div>
      </section>

      <section className="py-20 bg-gradient-to-r from-black to-zinc-800 text-white" id="contact">
        <div className="container">
          <div className="max-w-3xl mx-auto text-center">
            <h2 className="text-3xl md:text-4xl font-bold mb-6">Ready to transform your assessment experience?</h2>
            <p className="text-xl text-zinc-300 mb-8">
              Join thousands of university professors who are already creating engaging quizzes with QuizBase on any device, from desktops to smartphones and tablets.
            </p>
            <div className="flex flex-col sm:flex-row gap-4 justify-center">
              <NavLink
                to="/login"
                className={buttonVariants({ variant: "default", size: "lg" })}
              >
                Get started for free
                <svg className="ml-2 h-4 w-4 transition-transform group-hover:translate-x-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
                </svg>
              </NavLink>
              <a
                href="mailto:contact@quizbase.com"
                className={buttonVariants({ variant: "outline", size: "lg", className: "text-black" })}
              >
                Contact sales
                <svg className="ml-2 h-4 w-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"></path>
                </svg>
              </a>
            </div>
            <div className="mt-12 p-6 bg-white/10 rounded-lg backdrop-blur-sm border border-white/20">
              <div className="flex flex-col md:flex-row items-center justify-center gap-6">
                <div className="flex items-center gap-2">
                  <div className="bg-white/20 rounded-full p-2">
                    <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
                    </svg>
                  </div>
                  <span className="text-zinc-200">Free 14-day trial</span>
                </div>
                <div className="flex items-center gap-2">
                  <div className="bg-white/20 rounded-full p-2">
                    <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"></path>
                    </svg>
                  </div>
                  <span className="text-zinc-200">No credit card required</span>
                </div>
                <div className="flex items-center gap-2">
                  <div className="bg-white/20 rounded-full p-2">
                    <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"></path>
                    </svg>
                  </div>
                  <span className="text-zinc-200">Cancel anytime</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
