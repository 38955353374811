
import { Avatar, AvatarImage } from "@/components/ui/avatar";
import { formatDuration, humanDateFormat } from "@/lib/utils";
import { ColumnDef } from "@tanstack/react-table";
import { format } from "date-fns";
import { NavLink } from "react-router";
import { ResponseWithMetadata } from "@/types/response";
import { Popover, PopoverContent } from "@/components/ui/popover";
import { PopoverTrigger } from "@radix-ui/react-popover";
import { useIsSmallScreen } from "@/hooks/use-mobile";

export const ResponsesColumns: () => ColumnDef<ResponseWithMetadata>[] = () => {
  const isSmallScreen = useIsSmallScreen();

  return [
    {
      accessorFn: (row) => row,
      id: "profile",
      header: "",
      cell: (props) => {
        const response = props.getValue<{
          quiz_id: string;
          id: string;
          participant: {
            name: string; user_id: string, profile: {
              name: string;
              email: string;
              avatar_url: string;
              responseId: string;
            }
          }
        }>();

        if (!response) return null;

        return (
          <div>
            <NavLink
              to={`/studio/quiz/${response.quiz_id}/responses/${response.id}`}
              className="flex items-center gap-2 pl-4"
            >
              {response.participant.profile.avatar_url && (
                <Avatar className="size-[32px]">
                  <AvatarImage src={response.participant.profile.avatar_url} alt={response.participant.name} />
                </Avatar>
              )}
              <div className="flex flex-col">
                {response.participant.name}
                <p className="text-xs text-gray-600">{response.participant.profile.email}</p>
              </div>
            </NavLink>
          </div>
        );
      },
    },
    {
      id: "score",
      accessorFn: (answer) => {
        return { score: answer.score, max_score: answer.max_score };
      },
      header: "Score",
      cell: (props) => {
        const isCompleted = props.row.getValue<Date | null>("is_completed");
        const v = props.getValue<{ score: number; max_score: number }>();
        return isCompleted ? (
          <div>
            {v.score}/<span className="text-muted-foreground">{v.max_score}</span>
          </div>
        ) : (
          "-"
        );
      },
    },
    {
      id: "code",
      accessorKey: "participant.code",
      header: "Code",
      cell: (props) => {
        const code = props.getValue<string>();
        return <Popover>
          <PopoverTrigger>
            <div className="text-xs text-gray-600 text-ellipsis max-w-[120px] overflow-hidden">{code}</div>
          </PopoverTrigger>
          <PopoverContent>
            <p>{code}</p>
          </PopoverContent>
        </Popover>
      }
    },
    {
      id: "group",
      accessorKey: "participant.group.name",
      header: "Group",
      cell: (props) => {
        if (isSmallScreen) {
          return ""
        }
        const group = props.getValue<string>();
        return <span className="text-xs text-center">{group ?? "-"}</span>
      }
    },
    {
      id: "state",
      accessorFn: (row) => ({ state: row.state, reason: row.reason }),
      header: "State",
      cell: (props) => {
        const { state, reason } = props.getValue<{ state: string, reason: string }>();
        switch (reason) {
          case "timedout":
          case "timeout":
            return <span className="text-xs text-red-400">{reason}</span>
          default:
            return <div className="text-xs text-gray-600 flex flex-col">{state}</div>
        }
      },
    },
    {
      id: "duration",
      accessorKey: "duration",
      header: "Duration",
      cell: (props) => {
        const isCompleted = props.row.getValue<Date | null>("is_completed");
        // const duration = props.getValue<number>();
        const duration = formatDuration(props.getValue<number>());
        return isCompleted ? <div>{duration}</div> : "-";
      },
    },
    {
      id: "time",
      accessorFn: (row) => ({ isCompleted: row.is_completed, createdAt: row.created_at }),
      header: "Time",
      cell: (props) => {
        const { isCompleted, createdAt } = props.getValue<{ isCompleted: Date | null, createdAt: Date }>();
        const ended = isCompleted ? format(new Date(isCompleted), humanDateFormat) : "-";
        const started = createdAt ? format(new Date(createdAt), humanDateFormat) : "-";
        return <div className="text-xs text-gray-600 flex flex-col"><span>{started}</span> <span>{ended}</span></div>;
      },
    },
    {
      id: "is_completed",
      accessorKey: "is_completed",
      header: "",
      cell: () => null,
    },
    {
      id: "created_at",
      accessorKey: "created_at",
      header: "",
      cell: () => null,
    },
    {
      accessorKey: "id",
      header: "",
      cell: () => { }
    },
  ]
};
